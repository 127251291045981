<div class="app-header-popup-menu">
    <span class="wallet-address" *ngIf="httpService.user.wallet_address !== ''" (click)="copyWalletAddress()" matTooltip="Copy address">
        <img src="https://assets.credible.finance/Phantom-Icon-Transparent-Purple.png"
            *ngIf="walletService.current_provider === 'phantom'" />
        <img src="../../assets/images/solflare.png" *ngIf="walletService.current_provider === 'solflare'" />
        {{
            httpService.sliceAddress(httpService.user.wallet_address, 7)
        }}
    </span>

    <div class="divider"></div>

    <span class="diamonds-holder" *ngIf="httpService.user.wallet_address !== ''">
        <i class="fa-regular fa-gem"></i>
        {{ total_points }} diamonds
    </span>

    <div class="divider"></div>

    <span class="portfolio-holder" *ngIf="httpService.user.wallet_address !== ''">
        <i class="fa-solid fa-sack-dollar"></i>
        {{ total_balance }} USD
    </span>
    
    <div class="divider"></div>

    <div class="menu-item">
        <img src="https://assets.credible.finance/Logout.png" class="menu-item-image" alt="Loogout" />

        <div class="menu-item-name" (click)="logout()">Logout</div>
    </div>
</div>