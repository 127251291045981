<div class="app-transactions container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="tabs">
                <span
                    class="tab"
                    (click)="changeTab(tab.key)"
                    [ngClass]="{ 'tab-active': currentTab === tab.key }"
                    *ngFor="let tab of tabs"
                    >{{ tab.name }}</span
                >
            </div>
            <div class="tabs-divider"></div>
        </div>
    </div>

    <div class="row transactions">
        <app-my-investments
            *ngIf="currentTab === 'my-supply'"
        ></app-my-investments>
        <app-my-earnings *ngIf="currentTab === 'my-earnings'"></app-my-earnings>
        <app-my-withdrawals
            *ngIf="currentTab === 'my-withdrawals'"
        ></app-my-withdrawals>
        <app-my-drawdowns *ngIf="currentTab === 'my-drawdowns'"></app-my-drawdowns>
        <app-my-repayments
            *ngIf="currentTab === 'my-repayments'"
        ></app-my-repayments>
    </div>
</div>
