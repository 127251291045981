import {
    Component, OnInit 
} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
    
})
export class FooterComponent implements OnInit {
    currentTheme: string = 'light';
    currentDate: string = new Date().toDateString();
    currentYear: string = new Date().getFullYear().toString();

    constructor() { }

    ngOnInit(): void {

    }
}
