import {
    AfterViewChecked,
    ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit
} from '@angular/core';
import {
    Router, ActivatedRoute, NavigationEnd
} from '@angular/router';
import { HttpService } from '../services/http.service';
import { filter } from 'rxjs/operators';
import { getWindow } from 'ssr-window';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { LocalStorageService } from '../services/localstorage.service';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
    loading: boolean = true;
    showSidebar: boolean = this.getDefaults('showSidebar', true);
    showHeader: boolean = this.getDefaults('showHeader', true);
    showConnectWallet: boolean = this.getDefaults('showConnectWallet', false);
    currentCollapseStatus: boolean = this.getDefaults('sidebarCollapseStatus', false);

    width: any;
    window: Window;
    uncollapsedSidebarWidth: number = 250;
    collapsedSidebarWidth: number = 50;
    currentSidebarWidth: number = this.currentCollapseStatus ? this.collapsedSidebarWidth : this.uncollapsedSidebarWidth;
    nonSidebarWidth: number = 1920;

    constructor(public httpService: HttpService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cfAlertService: CfAlertService,
        private cdr: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
        private location: LocationStrategy) {
        this.window = getWindow();

        this.nonSidebarWidth = this.window.outerWidth;
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        console.log('width on resize', this.width);

        this.setSidebarWidth();
    }

    ngOnInit(): void {
        this.init();
        this.width = this.window.outerWidth;

        console.log('width on init', this.width);

        // this.location.pushState({ }, '', '', '');

        this.subscribeRouteChangeEvents();
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    subscribeRouteChangeEvents() {
        this.router.events.pipe(filter(event => {
            console.log('router event', event);

            return event instanceof NavigationEnd;
        })).subscribe(() => {
            const rt = this.getChild(this.activatedRoute);

            rt.data.subscribe((data: any) => {
                if (data.title && data.description)
                    this.httpService.setMetaData(data.title, data.description);

                this.showSidebar = 'showSidebar' in data ? data.showSidebar : true;
                this.showHeader = 'showHeader' in data ? data.showHeader : true;
                this.showHeader = 'showConnectWallet' in data ? data.showConnectWallet : true;

                this.setSidebarWidth();
            });
        });
    }

    getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        console.log('getChild', activatedRoute);

        if (activatedRoute.firstChild)
            return this.getChild(activatedRoute.firstChild);

        else
            return activatedRoute;
    }

    async init() {
        const getCurrencyConversionMap = this.httpService.getCurrencyConversionMap();
        const visualPrecision = this.httpService.setCurrenciesVisualPrecision();
        const getCurrentUser = this.httpService.getCurrentUser();

        await Promise.all([ getCurrencyConversionMap, visualPrecision, getCurrentUser ]).then(() => this.loading = false);
    }

    sidebarCollapsed(collapsed: boolean) {
        console.log('sidebarCollapsed', collapsed);

        this.currentCollapseStatus = collapsed;
        this.localStorageService.setItem('sidebarCollapseStatus', collapsed.toString());

        this.setSidebarWidth();
    }

    setSidebarWidth() {
        if (this.width < 768)
            this.showSidebar = false;

        if (this.currentCollapseStatus) {
            this.currentSidebarWidth = this.showSidebar ? this.collapsedSidebarWidth : 0;
            this.nonSidebarWidth = this.width - this.currentSidebarWidth;
        }
        else {
            this.currentSidebarWidth = this.showSidebar ? this.uncollapsedSidebarWidth : 0;
            this.nonSidebarWidth = this.width - this.currentSidebarWidth;
        }

        console.log('showSidebar', this.showSidebar);
        console.log('currentCollapseStatus', this.currentCollapseStatus);
        console.log('currentSidebarWidth', this.currentSidebarWidth);
    }

    ngOnDestroy() {
        console.log('storing');
        
        this.storePreferences();
    }

    storePreferences() {
        this.localStorageService.setItem('showSidebar', this.showSidebar.toString());
        this.localStorageService.setItem('showHeader', this.showHeader.toString());
        this.localStorageService.setItem('showConnectWallet', this.showConnectWallet.toString());
    }

    getDefaults(field_name: string, fallback: boolean) {
        const value = this.localStorageService.getItem(field_name);

        if (value === null) {
            this.localStorageService.setItem(field_name, fallback.toString());

            return fallback;
        }

        return value === 'true';
    }
}
