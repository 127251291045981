import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrl: './members.component.scss'
})

export class MembersComponent implements OnInit {
    loading: boolean = true;

    members: Array<any> = [];
    total_count: number = 0;

    page: number = 0;
    limit: number = 16;

    window: Window;

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService,
        private router: Router, private route: ActivatedRoute) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend.component.ts ngOnInit()');
        
        this.getMembers();
    }

    getMembers() {
        const data = {
            page: this.page,
            limit: this.limit
        };

        this.httpService.getMembers(data).subscribe(res => {
            this.members = res.data;
            this.total_count = res.total_count;
         
            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    pageChanged(event: any) {
        this.page = event;
        this.getMembers();
    }
}