<div class="privacy-policy-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="privacy-content">

            <h1 class="heading">Privacy Policy</h1>
            <br>
            <br>
            <div class="paragraph">
                <p>The Credible Finance mobile application (“App”) is owned and operated by Credible Finance Financial
                    Technology Private Limited, a company incorporated under the provisions of the Companies
                    Act, 2013, and having its registered office at SNO2/1A/2 PLNO3 VISAVA SOC, TALJAI ROAD
                    DHANKAWADIGAON,
                    Pune, Maharashtra, India, 411043
                    .For the purposes
                    of this Privacy Policy (“Privacy Policy”), “we”, “our” and “us” shall mean “Credible Finance” and
                    “you”
                    and “your” shall mean you as a User of the App (“User”),whether registered or not.
                </p>
                <p>This Privacy Policy shall be read along with the Terms of Use. The capitalized terms that are used in
                    this
                    Privacy
                    Policy but not defined herein shall have the same meaning as defined in the Terms ofUse.</p>

                <p>This Privacy Policy is incorporated into, and is subject to, the terms and conditions pertaining to
                    the
                    services
                    rendered by us (“Services”).</p>
                <p>This Privacy Policy sets out what information we collect and how and why we use it, when you access
                    the
                    App
                    and create
                    an account with us (”Account”). You are advised to read the Privacy Policy carefully before using
                    this
                    App.
                    To access or
                    use the App, you are required to accept the terms of this Privacy Policy. By accepting this Privacy
                    Policy,
                    you signify
                    that you have read, understood and agree to be bound by this Privacy Policy. We reserve the right to
                    update
                    or modify
                    this Privacy Policy at any time without prior notice and such changes shall be effective immediately
                    in
                    accordance
                    withClause
                </p>
                <p>
                    10.BY ACCEPTING THE PRIVACY POLICY, YOU EXPRESSLY CONSENT TO Credible Finance’s COLLECTION,
                    RETENTION,
                    ANALYSIS,
                    USE AND
                    DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY. BY ACCEPTING THE
                    PRIVACY
                    POLICY, YOU
                    EXPRESSLY CONSENT TO Credible Finance COLLECTION, RETENTION, ANALYSIS, USE AND DISCLOSURE OF YOUR
                    PERSONAL
                    INFORMATION IN
                    ACCORDANCE WITH THIS PRIVACY POLICY.
                </p>

                <ol>
                    <li>
                        <p class="bold">
                            INFORMATION COLLECTED:
                        </p>
                    </li>
                    <p>
                        We will collect personal information of the User such as name, date of birth, e-mail address,

                        phone number, pincode, PAN, SMS permission and phone permission, as detailed further

                        sub-clauses 1.6 and 1.7 below. While some User information has to be mandatorily provided,

                        others are optional and certain portions of the information will remain private. We shall always

                        let the User know which is which. Additional information may be gathered during subsequent

                        use of the App and/or availing of Services by the User, whenever the User chooses to provide it.
                    </p>
                    <br />

                    <ol>
                        <li>

                            <p>
                                Certain mandatory information will be collected at the time of registration on the App
                                which

                                is required to enable the User to login to the App and for us to:
                            </p>
                            <ol>
                                <li>
                                    verify the User’s identity
                                </li>
                                <li>
                                    determine the User’s eligibility for providing the facility using,

                                    inter alia, our proprietary technology and such additional rules that may be
                                    determined
                                    by
                                    us
                                </li>
                                <li>
                                    safeguard againstillegal activities like fraud, cheating, misappropriation,
                                    etc.Certain
                                    mandatory information will be
                                    collected at the time of registration on the App which is required to enable the
                                    User to
                                    login
                                    tot he App and for us to:
                                    <ol>
                                        <li>
                                            verify the User’s identity;
                                        </li>
                                        <li>
                                            determine the User’s eligibility for providing the facility using, inter
                                            alia,
                                            our
                                            proprietary technology and such
                                            additional rules that

                                            may be determined by us
                                        </li>
                                        <li>
                                            safeguard against illegal activities like fraud, cheating, misappropriation,
                                            etc.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <br />
                        <li>
                            For providing Services we require certain information such as name, date of birth, mobile

                            number, email address, residential address, current address, and certain information about
                            your

                            personal identity such as gender, age, etc. Further, we also collect and store your
                            username,

                            password, email address, and other security related information that you use for our sites
                            in

                            relation to our Services.
                        </li><br />
                        <li>
                            In order to use the facilities and Services available on the App, the User may be required,

                            from time to time, at the request of Credible Finance to provide certain additional personal
                            information

                            after registration on the App, which information shall be collected only upon receiving the
                            User’s

                            express consent.
                        </li><br />
                        <li>
                            We may also automatically receive and collect certain anonymous information in standard

                            usage logs through the web server, including mobile-identification information obtained from
                            the

                            equivalent of “cookies” sent to the App, including mobile network information, standard web
                            log

                            information, traffic to and from our App, tracking inside the App and any other available

                            information, from:
                            <ol>
                                <li>
                                    an IP address, assigned to the device used by the User
                                </li>
                                <li>
                                    the domain server through which the User accesses the App and the functions and

                                    features therein
                                </li>
                                <li>
                                    the type of device used by the User (“Device”)
                                </li>
                            </ol>
                        </li>
                        <br />
                        <li>
                            The User may choose to provide us with access to certain personal information stored by

                            third parties like social networking sites (e.g. Facebook and Twitter). We will have access
                            to

                            such information to the extent allowed by the User’s privacy settings on that site and the
                            User’s

                            specific authorization. In the event the User associates a User account managed by us with
                            an

                            account maintained with any third party to enable us to access certain information
                            maintained in

                            such third party managed accounts, the User agrees that we may collect, store and use such

                            information in accordance with this Privacy Policy.
                        </li>
                        <br />
                        <li>
                            After obtaining the User’s specific consent to allow the App to access the User’s SMS inbox,

                            we may collect, store, and monitor relevant information from text messages (SMS) received by

                            the Users from providers of services and/or products (including but not limited to retail
                            outlets,

                            financial institutions, mobile carriers and utility companies), that will enable us to
                            provide
                            better

                            access to financial services and products to the Users. We will only access business
                            messages

                            that originate from alphanumeric senders, which shall be used solely for the purposes of

                            providing the Services. We do not collect, read or store any personal SMSs.
                        </li>
                        <br />
                        <li>
                            <span class="bold">
                                How we use this information
                            </span>
                        </li>

                        <p>
                            We shall collect any SMS information for facilitating you any

                            financial services and such purposes as may be required by the Partners or as per applicable

                            law.
                        </p>


                        <li>
                            In order to enhance our ability to provide valuable Services and experiences to the User, we

                            may take one time access to:
                            <ol>
                                <li>
                                    automatically receive, collect and analyze your location

                                    information which may be accessed through a variety of methods including, inter
                                    alia,
                                    GPS,
                                    IP

                                    address, and cell tower location
                                </li>

                                <li>
                                    collect information pertaining to your Device and your

                                    usage thereof, including, inter alia, the names of the other applications on your
                                    mobile
                                    Device

                                    and how you use them, information about your Device, and information about your use
                                    of

                                    features or functions on your Device.
                                </li>
                                <li>
                                    file and media in order to enable you to upload

                                    documents solely for the purpose of onboarding or KYC checks.
                                </li>




                            </ol>
                            <p class="bold">How we use this information:</p>

                            <span>
                                We shall collect any Files & Media (Images) for facilitating KYC

                                journey, financial services and such purposes as may be required by the Partners or as
                                per

                                applicable law.
                            </span>
                        </li>
                        <br />
                        <li>
                            We collect a list of the installed applications’ metadata information which includes the

                            application name, package name, installed time, updated time, version name and version code

                            of each installed application on your device to assess your creditworthiness and enrich your

                            profile with pre-approved customized loan offers. This also includes having access and

                            permission to send you messages and notifications via your social media and instant
                            messaging

                            applications. You will not be able to use the App if you disable this access.

                            1.10 We may collect information with your consent from your mobile device like device
                            location,

                            device information (including storage, model, installed apps, Wi-Fi, mobile network), files
                            and

                            media, transactional and promotional SMS, communication information including contacts to

                            provide customized offerings.
                        </li>
                    </ol>
                    <br />
                    <li>
                        <p class="bold">
                            METHOD AND MANNER OF USE OF INFORMATION:
                        </p>
                        <ol>
                            <li>
                                When the User registers with the App, we will use the information supplied by the User
                                to

                                pull a credit report from credit bureaus with User consent, and identity verification
                                services
                                to

                                evaluate the User’s request in the context of the User’s complete financial situation.
                            </li><br />
                            <li>
                                We will also use the User information to enable activities and transactions that need to
                                occur

                                during the process of providing Services, such as
                                <ol>
                                    <li>
                                        generating and maintaining User profiles on the App;
                                    </li>
                                    <li>
                                        provide personalized features;facilitating collection activities as needed;
                                    </li>
                                    <li>
                                        maintaining regular communications with the User concerning transactions the
                                        User

                                        initiates, such as requesting information or assistance, submitting a
                                        transaction
                                        request, making

                                        payments, transferring funds, etc;
                                    </li>
                                    <li>
                                        modifying the App from time to time to cater to the User’s interests;
                                    </li>
                                    <li>
                                        delivering
                                        any administrative notices,
                                        alerts, advice, notification and communication

                                        relevant to your use of any facilities on the App through whatsapp, SMS,
                                        Facebook
                                        and
                                        other

                                        mediums;
                                    </li>
                                    <li>
                                        marketing research, project planning, troubleshooting problems, detecting
                                        protecting

                                        against error, fraud or other criminal activity;
                                    </li>
                                    <li>
                                        providing the App and the functions and features therein, efficiently
                                    </li>
                                    <li>
                                        and preserve social history as governed by existing

                                        law or policy.
                                    </li>
                                </ol>
                            </li><br />
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            SHARING OF INFORMATION
                        </p>

                        <ol>
                            <li>
                                We will not use User information for any purpose other than in connection with the App.
                                You

                                understand that in order to provide the Services, we will share the User information
                                with
                                third

                                parties who are providing the facilities availed through the App or otherwise and you
                                agree
                                to

                                such User information being shared. Credible Finance will not rent, sell or share User
                                information and

                                will not disclose any of the User’s personally identifiable information to third parties
                                or
                                affiliates,

                                unless:

                            </li>
                            <br />
                            <ol>
                                <li>
                                    it is pursuant to obtaining the User’s permission;
                                </li>
                                <li>
                                    it is in connection with the Services being rendered through the App;
                                </li>
                                <li>
                                    it is to help investigate, prevent or take action regarding unlawful and illegal
                                    activities;

                                    suspected fraud, potential threat to the safety or security of any person,
                                    violations of
                                    our
                                    Terms

                                    & Conditions, or as defense against legal claims;
                                </li>
                                <li>
                                    it is a case of special circumstances such as compliance with court orders,
                                    requests/order,

                                    notices from legal authorities or law enforcement agencies compel us to make such
                                    disclosure;
                                </li>
                                <li>
                                    it forms part of the information we share with advertisers on an aggregate basis;
                                </li>
                                <li>
                                    it improves personalisation, analysis and for offering new products/Services;

                                </li>
                                <li>
                                    it is for promotional purposes for other services being rendered by us; and
                                </li>
                                <li>
                                    it is required under an arrangement between us and the third party for product

                                    enhancement, analysis, risk and security purposes, communication purpose, etc.
                                </li>
                            </ol>
                            <li>
                                If all or some of the business, stock or assets of Credible Finance are acquired or
                                merged
                                with

                                another business entity, we will share all or some of your information with the
                                acquiring /
                                merged

                                entity to continue to provide the Service to you.
                            </li>
                            <li>
                                If you decline to submit information to us, then we will unfortunately not be in a
                                position
                                to

                                provide the Services to you.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">SERVICE PROVIDERS</p>
                        <ol>
                            <li>
                                We may employ third-party service providers and individuals due to the following
                                reasons:
                                <ol>
                                    <li>
                                        To facilitate our Services;
                                    </li>
                                    <li>
                                        To provide the service on our behalf
                                    </li>
                                    <li>
                                        To perform Service-related services;
                                    </li>
                                    <li>
                                        To assist us in analyzing how our Services are used.
                                    </li>
                                    <li>
                                        You acknowledge that the third-party service provider/agent/agencies will have
                                        access to

                                        your personal information on a need-to-know basis to assist us in rendering
                                        Service,
                                        and
                                        the

                                        service providers are restricted from using the same for any other reason. The
                                        third-party

                                        service providers are obligated not to disclose or use the information for any
                                        other
                                        purpose.
                                    </li>

                                </ol>
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN INDIA
                        </p>
                        <ol>
                            <li>
                                This Policy is intended for Users within the territory of India and govern your rights
                                as
                                per

                                applicable law within the territory of India. However, in the event you fall under a
                                jurisdiction

                                outside the purview of Indian law, Credible Finance will not be liable for any claim,
                                action
                                and/or
                                right

                                initiated/exercised by you as per the extant laws of that jurisdiction. Therefore, we
                                request
                                you

                                to kindly use the App accordingly.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            DATA RETENTION:
                        </p>
                        <ol>
                            <li>
                                We take reasonable steps to ensure that information about you is available only for so
                                long

                                as is necessary for the purpose for which it is processed, or longer if required under
                                any

                                contract, by applicable law, or for statistical purposes, subject to appropriate
                                safeguardsWe
                                take

                                reasonable steps to ensure that information about you is available only for so long as
                                is

                                necessary for the purpose for which it is processed, or longer if required under any
                                contract,
                                by

                                applicable law, or for statistical purposes, subject to appropriate safeguards.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            INFORMATION SECURITY
                        </p>
                        <ol>
                            <li>
                                The information provided by the User is stored in access controlled facilities with
                                restricted

                                access. User Information transmitted over the internet is protected through the use of

                                encryption, using the Secure Socket Layer (SSL) or equivalent protocols.The
                                informationprovided
                                by the User is stored in
                                access controlled facilities with restricted access. User

                                Information transmitted over the internet is protected through the use of encryption,
                                using
                                the

                                Secure Socket Layer (SSL) or equivalent protocols.

                            </li>
                            <li>
                                If a password is used to help protect User accounts and account information, it is the

                                responsibility of the User to keep the password confidential. You have to ensure that
                                you
                                always

                                log out, before sharing the Device with a third party and it is advised that the User
                                utilizes a

                                service to protect access to the User’s Device.

                            </li>
                            <li>
                                We shall use generally accepted industry standards to protect the User information

                                submitted to us, both during transmission and upon receipt. However, please be advised
                                that
                                no

                                method of transmission over the Internet, or method of electronic storage, is 100%
                                secure.

                                Therefore, even though we strive to use commercially acceptable means to protect User

                                information, we cannot guarantee its absolute security and your use of the App is at
                                your
                                sole

                                risk and discretion. We also cannot warrant that such User information may not be
                                misused in

                                the event our safeguards and protocols are breached by a malicious third-party. Further,
                                we
                                are

                                not liable to, nor can we fully control the actions of other users with whom you may
                                choose
                                to

                                share your information.

                            </li>
                            <li>
                                The collection, usage, and sharing of User information by us shall be in compliance with
                                the

                                Information Technology (Reasonable Security Practices and Procedures and Sensitive
                                Personal

                                Data or Information) Rules, 2011 and other applicable laws.
                            </li>
                            <li>
                                If any security breach comes to our knowledge, then we may take all steps required to

                                protect misuse of such information and may attempt to notify you electronically so that
                                you
                                can

                                take appropriate steps.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            PROCEDURE FOR REMOVING USERNAME FROM THE RECORDS
                        </p>
                        <ol>
                            <li>
                                If and when a User is desirous of having his/her name and other details removed from the

                                records of Credible Finance, immediately upon receiving the User’s written request to
                                that
                                effect we

                                shall, subject to the terms hereof, remove and/delete all such information.

                            </li>
                            <li>
                                If the User no longer wishes to receive notifications about our Services, The User may

                                change his/her notification preferences by contacting us at hello&#64;credible.finance.
                                We
                                reserve

                                the right to close the User account if the User opts out of receiving certain crucial
                                notices
                                that

                                are required to perform the Services through the App. The User may not opt-out of
                                receiving

                                notifications about due or past due amounts that the User owes Credible Finance or any
                                other

                                collections efforts.
                            </li>
                            <li>
                                Notwithstanding the foregoing, Credible Finance may retain information of the User if
                                mandated

                                under applicable law, even after receiving the User’s written request to remove/delete
                                all
                                such

                                information.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            PROCEDURE FOR CORRECTING INACCURACIES IN THE INFORMATION
                        </p>
                        <ol>
                            <li>
                                The User may correct or update any information online. In the event of loss of access

                                details, the User may retrieve the same or receive new access details by sending an
                                e-mail
                                to:

                                hello&#64;credible.finance

                                ADDRESS – SNO2/1A/2 PLNO3 VISAVA SOC, TALJAI ROAD DHANKAWADIGAON, Pune,
                                Maharashtra,
                                India, 411043

                                NAME –

                                TEL – +91

                                EMAIL – hello&#64;credible.finance
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">PRIVACY POLICY UPDATES</p>
                        <ol>
                            <li>
                                We reserve the right to change or update this Privacy Policy at any time. Such changes

                                shall be effective immediately upon posting on this App. Your access and use of the App

                                following any such change constitutes your agreement to follow and be bound by these
                                Privacy

                                Policy, as updated or modified. For this reason, we encourage you to review these
                                Privacy

                                Policy each time you access and use the App.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">Grievance Officer</p>
                        <ol>
                            <li>
                                In accordance with Information Technology Act, 2000 and rules made there under, the

                                name and contact details of our Grievance Officer are provided below:
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">
                            DISPUTE RESOLUTION
                        </p>
                        <ol>
                            <li>
                                In the event of any dispute, difference or claim arising out of this Privacy Policy the
                                same

                                shall be settled in accordance with the Laws of India through regular judicial process
                                and
                                the

                                courts of Bangalore, India shall have exclusive jurisdiction.
                            </li>
                        </ol>
                    </li><br />
                    <li>
                        <p class="bold">COOKIES</p>
                        <ol>
                            <li>
                                When You visit the App, one or more cookies will be sent to the computer/smartphone or

                                other devices being used by you. The cookies are either used to improve the quality of
                                the
                                App

                                or for storing your preferences as a user, improving search results and tracking user
                                trends.
                                You

                                have the option of disabling cookies via your browser preferences. However, if you
                                disable

                                cookies on your browser, some parts of the App may not function effectively.
                            </li>
                        </ol>
                    </li>
                </ol>
                <br />

                <p>If You have any complaints or grievances or concerns with respect to Platform or this Privacy Policy,
                    please
                    inform such matters to the designated Grievance Officer through electronic mail at
                    <a href="mailto:hello@credible.finance" style="color: white;"> hello&#64;credible.finance </a>
                </p>
            </div>
        </div>
    </div>

</div>