import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-lend',
    templateUrl: './lend.component.html',
    styleUrl: './lend.component.scss'
})

export class LendComponent implements OnInit {
    loading: boolean = true;
    pools: Array<any> = [];
    window: Window;

    tvl_usd = 0;
    outstanding_drawdown_usd = 0;

    currentTab = 'all';
    tabs = [{
        name: 'All',
        key: 'all'
    }];

    category_id: string = '';

    constructor(private httpService: HttpService, private cfAlertService: CfAlertService,
        private router: Router, private route: ActivatedRoute) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend.component.ts ngOnInit()');
        
        this.getCategories();
        this.getPools();
    }

    getCategories() {
        this.httpService.getCategories().subscribe(res => {
            res.data.forEach((item: any) => {
                this.tabs.push({
                    name: item.name,
                    key: item.category_id
                });
            });
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPools() {
        const data = {
            category_id: this.category_id
        };

        this.httpService.getPools(data).subscribe(res => {
            const data = res.data;

            this.pools = data;

            this.pools.forEach(x => {
                if (x.category_id === 'private-credit')
                    x.type = 'Private Credit';

                if (x.category_id === 'private-equity')
                    x.type = 'Private Equity';

                if (x.category_id === 'partner-protocols')
                    x.type = 'Partner Protocols';

                if (x.category_id === 'vault')
                    x.type = 'Vault';

                this.tvl_usd += x.stats.tvl_usd;
                this.outstanding_drawdown_usd += x.stats.outstanding_drawdown_usd;
            });

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    selectPool(category_id: string, pool_id: string) {
        this.router.navigate([ `/liquidity/${category_id}/${pool_id}` ]);
    }

    redirectToUrl(event: Event, url: string) {
        this.window.open(url, '_blank');

        event.preventDefault();
        event.stopImmediatePropagation();
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        console.log('TAB -> ', this.currentTab);

        this.category_id = this.currentTab;

        if (this.currentTab === 'all')
            this.category_id = '';

        this.getPools();

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}