import { Injectable } from '@angular/core';
import {
    HttpClient, HttpHeaders
} from '@angular/common/http';
import {
    BehaviorSubject, Observable
} from 'rxjs';
import { DecimalPipe } from '@angular/common';
import {
    Meta, Title
} from '@angular/platform-browser';
import IUser from '../interfaces/IUser';
import User from '../models/User';
import { LocalStorageService } from './localstorage.service';
import {
    getWindow, getDocument
} from 'ssr-window';
import {
    serverUrl, currencyConversionMapUrl,
    cluster
} from '../app/app.config';

import { WalletService } from './wallet.service';
import { PhantomService } from './phantom.service';
import { SolflareService } from './solflare.service';
import IPool from '../interfaces/IPool';
import { nullChecker } from '../app/helpers/nullChecker';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    registerStepsCompleted: boolean = this.getDefaultOnboardingStatus();
    currencyConversionMap: any = {};
    visualPrecision: any = {};
    user: IUser = new User();
    window: Window;
    document: Document;

    account = new BehaviorSubject({
        address: '',
        isConnected: false
    });

    user_id: BehaviorSubject<number> = new BehaviorSubject(0);

    socket: any;
    socketConnected: boolean = false;
    balances: any = {};
    countries: any = [];
    network: string = cluster;

    route_change: BehaviorSubject<any> = new BehaviorSubject(false);

    theme: BehaviorSubject<any> = new BehaviorSubject('dark');

    constructor(
        private http: HttpClient,
        private decimalPipe: DecimalPipe,
        private titleService: Title,
        private metaService: Meta,
        private localStorageService: LocalStorageService,
        private walletService: WalletService,
        private phantomService: PhantomService,
        private solflareService: SolflareService) {
        console.log('http service constructor');

        let network = localStorageService.getItem('network');

        if (network !== cluster) {
            network = cluster;

            localStorageService.setItem('network', network);
        }

        this.network = network;

        this.window = getWindow();
        this.document = getDocument();
    }

    getDefaultOnboardingStatus() {
        const registerStepsCompleted = this.localStorageService.getItem('rsc');

        if (registerStepsCompleted === null) {
            this.localStorageService.setItem('rsc', 'false');

            return false;
        }

        return registerStepsCompleted === 'true';
    }

    getCurrentTheme(): Observable<any> {
        return this.theme.asObservable();
    }

    setTheme(newTheme: any) {
        this.theme.next(newTheme);
    }

    getCurrencyConversionMap() {
        return new Promise<void>((resolve, reject) => {
            this.http.get<any>(currencyConversionMapUrl).subscribe(res => {
                this.currencyConversionMap = res.data;

                resolve();
            }, (err) => {
                console.error(err);

                resolve();
            });
        });
    }

    setCurrenciesVisualPrecision() {
        return new Promise<void>((resolve, reject) => {
            resolve();
            
            this.visualPrecision = {
                usdt: 2,
                USDT: 2,
                usdc: 2,
                USDC: 2,
                usd: 2,
                USD: 2
            };
        });
    }

    checkWalletAddress(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({}),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${serverUrl}connectWallet`, body, httpOptions);
    }

    checkWalletAddressV2(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({}),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${serverUrl}connectWalletV2`, body, httpOptions);
    }

    setMetaData(title: string, description: string = '', image: string = '') {
        const _title = title === '' ? 'Credible Finance' : `${title} | Credible Finance`;
        const _description = description === '' ? _title.slice(0, 160) : description.slice(0, 160);
        const _image = image === '' ? 'https://assets.credible.finance/CredibleWhitelogo1.png' : image;

        this.titleService.setTitle(_title);

        // Google
        this.metaService.addTag({
            name: 'description',
            content: _description
        });
        this.metaService.addTag({
            name: 'keywords',
            content: ''
        });
        this.metaService.addTag({
            name: 'author',
            content: 'Credible Finance'
        });
        this.metaService.addTag({
            name: 'application-name',
            content: 'Credible Finance'
        });
        this.metaService.addTag({
            name: 'copyright',
            content: '2023 - 2024'
        });

        // Twitter
        this.metaService.addTag({
            name: 'twitter:title',
            content: _title
        });
        this.metaService.addTag({
            name: 'twitter:description',
            content: _description
        });
        this.metaService.addTag({
            property: 'twitter:image',
            content: _image
        });
        this.metaService.addTag({
            property: 'twitter:card',
            content: 'summary'
        });

        // Facebook
        this.metaService.addTag({
            property: 'og:title',
            content: _title
        });
        this.metaService.addTag({
            property: 'og:description',
            content: _description
        });
        this.metaService.addTag({
            property: 'og:image',
            content: _image
        });
        this.metaService.addTag({
            property: 'og:type',
            content: 'article'
        });
        this.metaService.addTag({
            property: 'og:url',
            content: this.window.location.href
        });

        this.metaService.addTag({
            name: 'robots',
            content: 'index, follow'
        });

        this.setCanonicalUrl(this.window.location.href);
    }

    setCanonicalUrl(url: string) {
        const links = this.document.querySelectorAll('link[rel="canonical"]');

        if (links.length > 0)
            links[0].setAttribute('href', url);
    }

    getCurrentUser(callback: any = null) {
        // returning a promise is mandatory for app initializer service
        return new Promise<void>((resolve) => {
            if (!this.localStorageService.getItem('token')) {
                if (callback)
                    callback();

                resolve();
            }
            else {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/x-www-form-urlencoded',
                        token: this.getAuthToken()
                    })
                };

                this.http.get<any>(`${serverUrl}` + 'getCurrentUser', httpOptions).subscribe(res => {
                    this.user = res.data;

                    if ('connect_socials_step' in res.data)
                        this.user.connect_socials_step = res.data.connect_socials_step;

                    else
                        this.user.connect_socials_step = false;

                    if ('invite_code_step' in res.data)
                        this.user.invite_code_step = res.data.invite_code_step;

                    else
                        this.user.invite_code_step = false;

                    const completed = this.checkUserOnboardingStatus();

                    if (!completed) {
                        this.registerStepsCompleted = false;
                        this.localStorageService.setItem('rsc', 'false');
                    }

                    else {
                        this.registerStepsCompleted = true;
                        this.localStorageService.setItem('rsc', 'true');
                    }

                    console.log('registerStepsCompleted', this.registerStepsCompleted);

                    this.walletService.current_provider = this.user.provider;

                    if (this.user.provider === 'phantom')
                        this.phantomService.connectWallet();

                    else if (this.user.provider === 'solflare')
                        this.solflareService.connectWallet();

                    if (callback)
                        callback();

                    resolve();
                }, err => {
                    console.error(err.error);

                    if (err && err.error && err.error.code === 1700) {
                        this.localStorageService.removeItem('token');

                        if (callback)
                            callback();

                        resolve();
                    }
                    else {
                        if (callback)
                            callback();

                        resolve();
                    }
                });
            }
        });
    }

    getUserBalances() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        this.http.get<any>(`${serverUrl}getUserBalances`, httpOptions).subscribe((res) => {
            this.balances = res.data;
        });
    }

    setUserId(user_id: number) {
        return this.user_id.next(user_id);
    }

    getUserId() {
        return this.user_id.asObservable();
    }

    //commmon
    getCurrencyUrl(currency: string) {
        currency = currency.toLowerCase();

        return 'https://assets.seracle.com/currencies/' + currency + '.png';
    }

    getPrecision(currency: string) {
        if (this.visualPrecision[currency])
            return `0.0-${this.visualPrecision[currency]}`;

        return '0.0-2';
    }

    getInrFormatting(input: number) {
        return this.decimalPipe.transform(input, '0.0-0');
    }

    getUsdFormatting(input: number) {
        return this.decimalPipe.transform(input, '0.0-2');
    }

    toFixedNumber(input: number, digits: number, base: number) {
        const pow = Math.pow(base || 10, digits);

        return Math.round(input * pow) / pow;
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    sliceAddress(str: string, length: number = 5) {
        return str.slice(0, length) + '...' + str.slice(-length);
    }

    getMinMaxInterestRatePool(pool: IPool) {
        let min = 100;
        let max = -100;

        pool.lending_yield_rates.forEach((x) => {
            if (min > x.apy)
                min = x.apy;

            if (max < x.apy)
                max = x.apy;
        });

        return {
            min,
            max
        };
    }

    getVaults(data: any) {
        if (nullChecker(data))
            data = {};

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getVaults?pool_id=${data.pool_id}`, httpOptions);
    }

    getCategories() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getCategories`, httpOptions);
    }

    getPools(data: any) {
        if (nullChecker(data))
            data = {};

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPools?category_id=${data.category_id}`, httpOptions);
    }

    getPoolDetails(pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPoolDetails?pool_id=${pool_id}`, httpOptions);
    }

    getPoolRewards(data: any) {
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getPoolRewards?pool_id=${pool_id}`, httpOptions);
    }

    getPoolActivity(data: any) {
        const page = data.page;
        const limit = data.limit;
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPoolActivity?page=${page}&limit=${limit}&pool_id=${pool_id}`, httpOptions);
    }

    getVaultAllocation(data: any) {
        const page = data.page;
        const limit = data.limit;
        const vault_id = data.vault_id;

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getVaultAllocation?page=${page}&limit=${limit}&vault_id=${vault_id}`, httpOptions);
    }

    getPoolWithdrawals(data: any) {
        const page = data.page;
        const limit = data.limit;
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getPoolWithdrawals?page=${page}&limit=${limit}&pool_id=${pool_id}`, httpOptions);
    }

    getInvestments(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getInvestments?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getInvestments?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getClaimedInterests(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getClaimedInterests?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getClaimedInterests?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getHomepageStats() {
        return this.http.get<any>(`${serverUrl}getHomepageStats`);
    }

    getPrincipalWithdrawals(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawals?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawals?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getLendingCurrencies() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getLendingCurrencies`, httpOptions);
    }

    getInvestmentStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getInvestmentStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getInvestmentStats?filter=${filter}`, httpOptions);
        }
    }

    getClaimedInterestStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getClaimedInterestStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getClaimedInterestStats?filter=${filter}`, httpOptions);
        }
    }

    getPrincipalWithdrawalStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawalStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawalStats?filter=${filter}`, httpOptions);
        }
    }

    getLoyaltyPoints() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getLoyaltyPoints`, httpOptions);
    }

    getLoyaltyLeaderboard(limit: number, page: number) {
        const httpOptions = {};

        return this.http.get<any>(`${serverUrl}getLoyaltyLeaderboard?limit=${limit}&page=${page}`, httpOptions);
    }

    updateReferral(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}` + 'updateReferral', data, httpOptions);
    }

    redirectionTx(blockchain_txid: string) {
        if (this.network === 'devnet')
            this.window.open(`https://explorer.solana.com/tx/${blockchain_txid}?cluster=devnet`, '_blank');

        else
            this.window.open(`https://explorer.solana.com/tx/${blockchain_txid}`, '_blank');
    }

    redirectionAddress(wallet_address: string) {
        if (this.network === 'devnet')
            this.window.open(`https://explorer.solana.com/address/${wallet_address}?cluster=devnet`, '_blank');

        else
            this.window.open(`https://explorer.solana.com/address/${wallet_address}`, '_blank');
    }

    getNfts() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getNfts`, httpOptions);
    }

    getPoolStats(pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPoolStats?pool_id=${pool_id}`, httpOptions);
    }

    getLendUserPortfolioCharts() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserPortfolioCharts`, httpOptions);
    }

    getLendUserActivity(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserActivity?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserRecentTransactions(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserRecentTransactions?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserPoolStats(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserPoolStats?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserAssets(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserAssets?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserPortfolioCharts() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserPortfolioCharts`, httpOptions);
    }

    getBorrowUserActivity(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserActivity?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserRecentTransactions(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserRecentTransactions?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserPoolStats(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserPoolStats?page=${page}&limit=${limit}`, httpOptions);
    }

    updateLendUserUnclaimedInterest(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}lendDashboard/updateUserUnclaimedInterest`, body, httpOptions);
    }

    updateUserRepaymentAmount(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}borrowDashboard/updateUserRepaymentAmount`, body, httpOptions);
    }

    getDrawdowns(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getDrawdowns?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getDrawdowns?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getDrawdownStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getDrawdownStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getDrawdownStats?filter=${filter}`, httpOptions);
        }
    }

    getRepayments(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getRepayments?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getRepayments?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getRepaymentStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getRepaymentStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getRepaymentStats?filter=${filter}`, httpOptions);
        }
    }

    createInvestment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createInvestment`, body, httpOptions);
    }

    updateInvestment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateInvestment`, body, httpOptions);
    }

    claimInterest(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}claimInterest`, body, httpOptions);
    }

    updateInterestWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateInterestWithdrawal`, body, httpOptions);
    }

    requestPrincipalWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}requestPrincipalWithdrawal`, body, httpOptions);
    }

    updatePrincipalWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updatePrincipalWithdrawal`, body, httpOptions);
    }

    createDrawdown(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createDrawdown`, body, httpOptions);
    }

    updateDrawdown(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateDrawdown`, body, httpOptions);
    }

    createRepayment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createRepayment`, body, httpOptions);
    }

    updateRepayment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateRepayment`, body, httpOptions);
    }

    getMembers(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getMembers?page=${data.page}&limit=${data.limit}`, httpOptions);
    }

    onboardingStepCompleted() {
        const rsc = this.localStorageService.getItem('rsc');

        if (rsc === null)
            return false;

        return rsc === 'true';
    }

    checkUserOnboardingStatus() {
        const a = this.user.wallet_address !== '';
        const b = this.user.display_name;
        const c = this.user.profile_picture;
        const d = this.user.telegram?.group_joined;
        const e = this.user.twitter?.group_joined;
        const f = this.user.referred_by;
        const g = this.user.terms_and_conditions;

        if (a && b && c && d && e && f && g)
            return true;

        // console.log('onboarding user', this.user);
        // console.log('onboarding status', a, b, c, d, e, f, g);

        return false;
    }
}