import {
    Component, HostListener, Inject, OnDestroy, OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { nullChecker } from '../helpers/nullChecker';

@Component({
    selector: 'app-borrower-dashboard',
    templateUrl: './borrower-dashboard.component.html',
    styleUrl: './borrower-dashboard.component.scss'
})

export class BorrowerDashboardComponent implements OnInit, OnDestroy {
    width: any;
    window: Window;
    currentTab = 'pools';
    tabs = [{
        name: 'Pools',
        key: 'pools'
    }, {
        name: 'Repayments',
        key: 'repayments'
    }];
    platformId: object = {};

    chartDataInvestment: Array<any> = [];
    chartDataDrawdown: Array<any> = [];
    activites: Array<any> = [];
    activity_page = 0;
    activity_limit = 5;
    pools_page = 0;
    pools_limit = 5;

    total_balance: number = 0;
    active_pools: Array<{
        pool_id: string,
        pool_name: string,
        user_balance: number,
        pool_currency: string,
        pool_balance: number,
        total_available: number,
        total_outstanding:number,
        pool_logo: string,
        animated_weight: number,
        actual_weight: number,
        outstanding_drawdown: number,
        category_id: string
    }> = [];

    chart_loaded: boolean = false;

    setIntervalIds: any = {};
    animation_duration: number = 1500;
    animation_steps_count: number = 15;

    recentTransactions: Array<any> = [];
    recentTransactionsPage = 0;
    recentTransactionsLimit = 5;

    repayment_principal_usd : number = 0;
    repayment_interest_usd : number = 0;
    repayment_amount_updated_at : number = new Date().getTime();
    repayment_amount_tooltip_text : string = '';
    repayment_amount_loaded : boolean = false;

    total_points: number = 0;

    drawdowns_loading: boolean = false;
    drawdowns: Array<any> = [];

    outstanding_drawdown_usd: number = 0;

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService,
        private route: ActivatedRoute, private router: Router, @Inject(PLATFORM_ID) platformId: object) {
        this.window = getWindow();
        this.platformId = platformId;
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.width = this.window.innerWidth;

        if (this.httpService.user.wallet_address !== '') {
            this.getBorrowUserActivity();
            this.getBorrowUserPortfolioCharts();
            this.getBorrowUserRecentTransactions();
            this.getBorrowUserPoolStats();
            this.getDrawdowns();
            this.updateUserRepaymentAmount();
            this.getLoyaltyPoints();
        }

        else {
            this.chartDataDrawdown = this.getDummyChartData();
            this.chart_loaded = true;
        }

        if (isPlatformBrowser(this.platformId))
            setTimeout(() => this.animateWeights(), 1000);

        this.checkDefaultTab();
    }

    checkDefaultTab() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.key === tab))
                    this.currentTab = tab;
            }
        });
    }

    getDummyChartData() {
        const data = [];

        let current_time = new Date().setSeconds(0, 0);

        for (let i = 0; i < 30; i++) {
            data.push({
                x: current_time,
                y: 0
            });

            current_time = new Date(current_time - (24 * 60 * 60 * 1000)).setSeconds(0, 0);
        }

        return data;
    }

    getBorrowUserPortfolioCharts() {
        this.httpService.getBorrowUserPortfolioCharts().subscribe(res => {
            this.chartDataDrawdown = !nullChecker(res.data.outstandingdrawdownamountvsdate_30) ? res.data.outstandingdrawdownamountvsdate_30 : this.getDummyChartData();

            this.chart_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getBorrowUserPoolStats() {
        this.httpService.getBorrowUserPoolStats(this.pools_page, this.pools_limit).subscribe(res => {
            const data = res.data.poolStats;

            this.total_balance = res.data.total_amount_to_be_repaid;
            this.outstanding_drawdown_usd = res.data.total_outstanding_amount;

            this.active_pools = data.map((item: any) => {
                return {
                    pool_id: item.pool_id,
                    total_available: item.total_available,
                    total_outstanding: item.total_outstanding,
                    pool_name: item.name,
                    pool_currency: 'usdc',
                    pool_logo: item.logo,
                    animated_weight: 0,
                    category_id: item.category_id
                };
            });
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    animateWeights() {
        const per_step_duration = this.animation_duration / this.animation_steps_count;

        let i = 0;

        this.active_pools.forEach(pool => {
            const difference = pool.actual_weight - pool.animated_weight;

            const step_size = difference / this.animation_steps_count;

            this.setIntervalIds[pool.pool_id] = setInterval(() => {
                if (pool.animated_weight >= pool.actual_weight) {
                    clearInterval(this.setIntervalIds[pool.pool_id]);

                    return;
                }

                if (pool.animated_weight + step_size > pool.actual_weight)
                    pool.animated_weight = pool.actual_weight;

                else
                    pool.animated_weight = pool.animated_weight + step_size;

                console.log(++i, pool.animated_weight);
            }, per_step_duration);
        });
    }

    getBorrowUserActivity() {
        this.httpService.getBorrowUserActivity(this.activity_page, this.activity_limit).subscribe(res => {
            this.activites = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getBorrowUserRecentTransactions() {
        this.httpService.getBorrowUserRecentTransactions(this.recentTransactionsPage, this.recentTransactionsLimit).subscribe(res => {
            this.recentTransactions = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    updateUserRepaymentAmount() {
        this.repayment_amount_loaded = false;

        this.httpService.updateUserRepaymentAmount({}).subscribe((res: any) => {
            this.repayment_principal_usd = res.data.repayment_principal_usd;
            this.repayment_interest_usd = res.data.repayment_interest_usd;
            this.repayment_amount_updated_at = res.data.updated;

            this.repayment_amount_tooltip_text = `Last updated at ${new Date(this.repayment_amount_updated_at).toLocaleString()}`;

            this.repayment_amount_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error('updateUserUnclaimedInterest error');
            console.error(err);
        });
    }

    getLoyaltyPoints() {
        this.httpService.getLoyaltyPoints().subscribe((res) => {
            this.total_points = res.data.total_points;
        }, (err: HttpErrorResponse) => {
            console.error('getLoyaltyPoints error');
            console.error(err);
        });
    }

    getDrawdowns() {
        this.drawdowns_loading = true;

        const body = {
            page: 0,
            limit: 5,
            token_required: true,
            usage: 'borrow_dashboard',
            status: 'completed'
        };

        this.httpService.getDrawdowns(body).subscribe((res: any) => {
            this.drawdowns = res.data;

            this.drawdowns_loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.drawdowns_loading = false;

            this.cfAlertService.showError(err);
        });
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    redirectToBorrowPoolDetails(tab: string, drawdown_id: string) {
        let url = 'tokenize';

        if (!nullChecker(this.active_pools))
            url = `tokenize/${this.active_pools[0].category_id}/${this.active_pools[0].pool_id}`;

        this.router.navigate([ url ], {
            queryParams: {
                tab: tab,
                drawdown_id: drawdown_id
            }
        });
    }

    redirectToExternalUrl(url: string) {
        this.window.open(url, '_blank');
    }

    redirectToPool(category_id: string, pool_id: string) {
        this.router.navigate([ `/tokenize/${category_id}/${pool_id}` ], {
            queryParams: {
                tab: 'repay'
            }
        });
    }

    ngOnDestroy(): void {
        for (const key in this.setIntervalIds)
            clearInterval(this.setIntervalIds[key]);
    }
}
