import {
    DatePipe, isPlatformBrowser 
} from '@angular/common';
import {
    Component, EventEmitter, Inject, Input, OnInit, Output, 
    PLATFORM_ID
} from '@angular/core';
import * as echarts from 'echarts';
import { getDocument } from 'ssr-window';
import { nullChecker } from '../../helpers/nullChecker';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: [ './line-chart.component.scss' ],
    providers: [ DatePipe ]
})

export class LineChartComponent implements OnInit {
    @Input() dataOne: any;
    @Input() dataTwo: any;
    @Input() title: string = '';
    @Input() currency: string = '';
    @Input() suffix: boolean = true;
    @Input() height: number = 420;
    document: Document;
    platformId: object = {};

    @Output() showCharts = new EventEmitter<any>();
    SelectedChartDays: string = '30D';
    myChart!: echarts.ECharts;
    chartId: string = new Date().getTime().toString();

    constructor(private datePipe: DatePipe, @Inject(PLATFORM_ID) platformId: object) {
        this.document = getDocument();
        this.platformId = platformId;
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId))
            this.initializeChart();
    }

    initializeChart() {
        setTimeout(() => {
            const chartDom = this.document.getElementById(this.chartId)!;

            console.log('chartDom', chartDom);

            this.myChart = echarts.init(chartDom);

            console.log('this.myChart', this.myChart);

            this.loadchart();
        }, 300);
    }

    loadchart() {
        setTimeout(() => {
            type EChartsOption = echarts.EChartsOption;
            let option: EChartsOption;

            const valueListOne = this.dataOne.map((item: any) => {
                return parseFloat(item.y).toFixed(2);
            });

            const valueListTwo = this.dataTwo.map((item: any) => {
                return parseFloat(item.y).toFixed(2);
            });

            let dateList = [];

            if (!nullChecker(valueListOne)) {
                dateList = this.dataOne.map((item: any) => {
                    return this.datePipe.transform(item.x, 'd MMM');
                });
            }

            if (!nullChecker(valueListTwo)) {
                dateList = this.dataTwo.map((item: any) => {
                    return this.datePipe.transform(item.x, 'd MMM');
                });
            }

            const legend_data = !nullChecker(valueListOne) && !nullChecker(valueListTwo) ? [ 'Tokenize, Supply' ] : [];

            // eslint-disable-next-line prefer-const
            option = {
                title: {
                    text: this.title,
                    textStyle: {
                        fontSize: 15
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    textStyle: {
                        color: '#FFFFFF'
                    },
                    inactiveColor: '#72717F',
                    data: legend_data
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: dateList
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: this.suffix ? '{value}' + ' ' + this.currency : this.currency + ' ' + '{value}'
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'grey',
                            width: 0.1
                        }
                    }
                },
                series: [
                    {
                        name: 'Tokenize',
                        type: 'line',
                        stack: 'Total',
                        data: valueListTwo,
                        smooth: true
                    },
                    {
                        name: 'Supply',
                        type: 'line',
                        stack: 'Total',
                        data: valueListOne,
                        lineStyle: {
                            color: '#cf0'
                        },
                        smooth: true
                    }
                ]
            };

            option && this.myChart.setOption(option, true);
        }, 400);
    }

    showChart(event: any) {
        this.SelectedChartDays = event;
        this.showCharts.emit(event);
    }
}
