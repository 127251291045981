import {
    Component, Input, 
    OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import User from '../../models/User';
import { LocalStorageService } from '../../services/localstorage.service';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'app-header-popup-menu',
    templateUrl: './header-popup-menu.component.html',
    styleUrl: './header-popup-menu.component.scss'
})
export class HeaderPopupMenuComponent implements OnInit {
    @Input() provider: string = '';
    @Input() wallet_address: string = '';
    total_points: number = 0;
    total_balance: number = 0;
    pools_page = 0;
    pools_limit = 10;

    constructor(
        private router: Router,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        public httpService: HttpService,
        private localStorageService: LocalStorageService,
        private cfAlertService: CfAlertService,
        private clipboardService: ClipboardService
    ) {}

    ngOnInit(): void {
        this.getLoyaltyPoints();
        this.getLendUserPoolStats();
    }

    logout() {
        if (this.walletService.current_provider === 'phantom') {
            this.phantomService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        else if (this.walletService.current_provider === 'solflare') {
            this.solflareService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        this.localStorageService.removeItem('token');

        this.httpService.user = new User();

        this.router.navigate([ '/' ]);
    }

    getLoyaltyPoints() {
        this.httpService.getLoyaltyPoints().subscribe((res) => {
            this.total_points = res.data.total_points;
        }, (err: HttpErrorResponse) => {
            console.error('getLoyaltyPoints error');
            console.error(err);
        });
    }

    copyWalletAddress() {
        this.clipboardService.copy(this.httpService.user.wallet_address);
        this.cfAlertService.showSuccess('Wallet address copied');
    }

    getLendUserPoolStats() {
        this.httpService.getLendUserPoolStats(this.pools_page, this.pools_limit).subscribe(res => {
            const data = res.data.records;

            this.total_balance = res.data.balance;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }
}
