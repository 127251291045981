<div class="app-footer container-fluid">
    <div class="row">
        <div class="d-flex justify-content-center">
            <span class="powered-by">
                © 2019 - {{ currentYear }} Copyright
            </span>
        </div>
    </div>

    <div class="row">
        <span class="warning">
            The information provided is for the convenience of accredited
            investors only. Data and values are accurate as of
            {{ currentDate }}, but may change over time. Please note that past
            performance is not a reliable indicator of future outcomes.
            Investing carries inherent risks, including the possibility of
            losing the entire investment. Credible does not offer investment
            advice and cannot vouch for the reliability, accuracy, or
            completeness of information from third parties. This material is not
            an offer or solicitation to buy or sell securities and should not be
            used as the basis for any investment decision. APR rates are subject
            to change.
        </span>
    </div>
</div>
