<div class="terms-condition-container">
    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="terms-content">
            <h1 class="heading">Terms and conditions</h1>
            <br>
            <br>
            <div class="paragraph">
                <p>
                    The Credible Finance mobile application (“App”) is owned and operated by Blockstore Private Limited,
                    a
                    company incorporated under the provisions of the Companies Act, 2013, and having its registered
                    office
                    at
                    SNO2/1A/2 PLNO3 VISAVA SOC, TALJAI ROAD DHANKAWADIGAON, Pune, Maharashtra, India, 411043
                    . To
                    access or use the App on any compatible device and/or avail our Services, you are required to accept
                    these
                    Terms and
                    Conditions of Use (“T&Cs”). By accepting these T&Cs, you signify that you have read, understood and
                    agree to
                    be bound by
                    these T&Cs and any other applicable law, whether or not you are a registered member of the App. As
                    used
                    herein, “User”
                    shall mean anyone who uses or accesses the App on any computer, mobile phone, tablet, console or
                    other
                    device
                    (collectively, “Device”) and/or avails the Services. Your continued use of the App and/or availing
                    of
                    the
                    Services shall
                    constitute your acceptance to the T&Cs, as revised from time to time. If you do not agree with these
                    T&Cs,
                    please do not
                    access and/or use the App or avail such Services. For the purposes of these T&Cs, “we”, “our” and
                    “us”
                    shall
                    meanCredible Finance, and/or third-party service providers engaged by Credible Finance to render
                    certain
                    Services on the App and
                    ‘you’ and
                    ‘your’ shall mean a User who meets the eligibility criteria set out below.

                </p>
                <ol>
                    <br />
                    <li>
                        <p class="bold">
                            TERMS AND CONDITIONS
                        </p>
                        <ol>
                            <li>
                                We reserve the right to update or modify these T&Cs at any time. Your access and use of
                                the
                                App
                                following any such
                                change constitutes your agreement to follow and be bound by these T&Cs, as updated or
                                modified.
                                For this reason, we
                                encourage you to review these T&Cs each time you access and use the App and/or avail our
                                Services.

                            </li>
                        </ol>
                    </li>
                    <br />
                    <br />
                    <li>
                        <p class="bold">SERVICES</p>
                        <ol>
                            <li>
                                We and our affiliates provide certain services to various banks and non-banking
                                financial
                                companies (“Partner(s)“) for
                                enabling Users to use payment services on our payment is through InApp Credits or Pre
                                Funded
                                Wallets on online payments
                                using credit facilities (“Credit Line” and/or “Revolving Credit Line”) offered by our
                                Partners,
                                enabling hassle free
                                shopping experience to the Users for their daily expenses (“Services”).

                            </li>
                            <li>
                                . The App is not linked to the User’s bank account. Instead of actual money in the bank,
                                we
                                have
                                set up a post-paid
                                limit for each User, which is provided by our Partners, by way of a Credit Line
                                (“Credible
                                Finance
                                Limit”), wherein the User
                                downloads the App, provides basic information and is either rejected or qualified for
                                theCredible Finance Limit. ThisCredible Finance Limit can be used via scan and pay for
                                transactions with accepting Hospitals.

                            </li>
                            <li>
                                We shall endeavor to provide you with the Credible Finance Limit, offering payment
                                services
                                through
                                InApp Credits or Prefunded
                                Wallets on online payments enabling shopping experience to the Users for their daily
                                expenses.
                                We shall facilitate the
                                execution of the relevant documents and / or agreement in relation to the Credible
                                Finance
                                Limit
                                for
                                each User that shall be
                                ascertained by the Partner (defined hereinbelow) in conjunction with us. We do not
                                decide or
                                ascertain the Credible Finance
                                Limit for the Users.

                            </li>
                            <li>
                                The User may avail our Services and make purchases to be paid for at a later point in
                                time.
                                The
                                outstanding amount
                                payable by the User at the end of each month shall be treated as an amount utilized from
                                the
                                Credit Line given to the
                                User by the Partner on the terms and conditions agreed upon between the User and the
                                concerned
                                Partner.
                            </li>
                            <li>
                                Credible Finance is the technology and operating partner to its banking and non-banking
                                financial
                                partners (“Partner(s)”). As
                                a service provider, we shall undertake communication, transaction and processing on
                                behalf
                                of
                                our Partners. At no point
                                are we representing ourselves as a lending or financial services company. All terms
                                pertaining
                                to your Credible Finance Limit
                                with the Partner, if any, are set out in the documents and/ or agreement executed by you
                                with
                                such respective Partner.

                            </li>
                            <li>
                                We reserve the right without any prior notice to: (a) add new services; (b) modify
                                existing
                                Services; and/or (c) remove
                                portions of the Services, as and when we deem fit and at our sole discretion.
                            </li>
                            <>
                                We reserve the right to charge a convenience fee, or any fee or charge, for facilitating
                                any
                                existing or new Services,
                                as and when we deem fit and at our sole discretion.

                                <li>
                                    In connection with the Services availed under these T&Cs, you agree to:
                                    <ol>
                                        <li>
                                            allow us (or our third party service providers), on our own or on behalf of
                                            our
                                            Partners, to send you payment reminders
                                            from time-to- time; and

                                        </li>
                                        <li>
                                            allow us (or our third party service providers), to engage in activities to
                                            collect
                                            repayment of the availed Credible Finance
                                            Limit that you failed to pay the concerned Partners, pursuant to these: (a)
                                            T&amp;Cs;
                                            and/or
                                            (b) terms and conditions of the
                                            third party service provider and/or the Partner. These activities may
                                            involve,
                                            inter
                                            alia, contacting either you
                                            directly or any other contact details provided by you at the time of
                                            creation of
                                            Account, submitting your information to
                                            a collection agency, or taking legal action.

                                        </li>
                                    </ol>
                                </li>

                        </ol>
                    </li>
                    <br />
                    <br />
                    <li>
                        <p class="bold">
                            ELIGIBILITY
                        </p>
                        <ol>
                            <li>
                                You represent and warrant that you are competent and eligible to enter into a legally
                                binding
                                agreement and be bound by
                                these T&Cs. You shall not access and use the App or avail our Services if you are not
                                competent
                                to contract under the
                                applicable laws, rules and regulations.

                            </li>
                            <li>
                                For accessing the App and availing Services, you, as a User, further represent that you
                                are
                                an
                                Indian national having
                                tax residency in India. You also represent and assure that you are not a tax resident of
                                any
                                other country.

                            </li>
                            <li>
                                We maintain the right to render Services to only those Users who: (a) are competent to
                                enter
                                into legally binding
                                contracts; (b) have made the representations as provided in Clause 3.2 above; and (c)
                                also
                                qualify under the internal
                                policy(ies) for the same determined solely by us from time to time. We shall have the
                                sole
                                right
                                to change, modify, add
                                or remove, in whole or in part, its internal policy(ies), in relation to the provision
                                of
                                the
                                Services at any time
                                without any prior written notice of intimation to the Users. Further, we shall have the
                                right to
                                not entertain any
                                requests in relation to the same, from the Users, towards such Services without
                                assigning
                                any
                                reason.

                            </li>
                            <li>
                                We shall have the right to not process a transaction through the App, if we, in our sole
                                discretion, determine that such
                                a transaction undertaken by a User is not authorized by such User or that the
                                transaction is
                                not
                                genuine or suspicious.

                            </li>
                            <li>
                                If you represent a company, partnership firm or sole proprietorship, you shall be
                                eligible
                                to
                                access the App and avail
                                of the features and facilities on its behalf only if you have been duly authorized by
                                way of
                                necessary corporate action,
                                as may be prescribed statutorily and/or under the charter documents of such entity and
                                further,
                                if you have fulfilled
                                such additional eligibility requirements as we may require in connection therewith.

                            </li>
                        </ol>
                    </li>
                    <br />
                    <br />
                    <li>
                        <p class="bold">
                            REGISTRATION
                        </p>

                        <ol>
                            <li>
                                You have to be registered as a User in order to avail the Credible Finance Limit , and
                                by
                                accepting
                                the terms and conditions
                                at the Partner website, you will be registered as a User with us on the App, and your
                                User
                                account will be created on
                                the App as per the procedure laid down in the authentication process below.

                            </li>
                            <li>
                                Any additional T&Cs with respect to the creation and use of the User account, if any,
                                specified
                                on App shall also be
                                applicable to you along with these T&Cs.

                            </li>
                            <li>
                                For the purposes of the registration of your account with us, you agree and understand
                                that
                                your
                                personal information,
                                including your phone number, PAN card, DOB and it will be shared by us with the Partner,
                                in
                                order to evaluate and
                                register your account for availing the Services, and or any other service provided by
                                us, as
                                the
                                case may be.

                            </li>
                            <li>
                                You shall ensure that:
                                <ol>
                                    <li>
                                        you furnish all information required for your availing of the Services as
                                        required
                                        by us
                                        from time to time; and
                                    </li>
                                    <li>
                                        all information provided by you is true, correct, accurate and complete. In
                                        order to
                                        facilitate the smooth availing of
                                        the Services, you hereby agree to ensure that you shall immediately update the
                                        data
                                        provided by you in the event of any
                                        changes thereto.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Every time you wish to avail a service with a new Partner, you shall be prompted to
                                accept
                                the
                                respective terms and
                                conditions applicable for the relevant Partner(s), as mentioned in Clause 2.4 above.
                                Your
                                acceptance of such new terms
                                shall not mean the creation of a new user account with us. However, the terms accepted
                                by
                                you
                                for the respective Partner
                                shall be in force in conjunction with these T&Cs.

                            </li>
                            <li>
                                You understand and accept that your acceptance of any terms for any products or
                                services,
                                whether with third-parties or
                                other products and Services offered by us, shall not mean that such terms will
                                substitute
                                the
                                provisions and terms
                                contained herein. The various terms accepted by you shall be in force for such duration
                                as
                                specified in the respective
                                terms.

                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">AUTHENTICATION</p>
                        <ol>
                            <li>
                                In order for you to register for availing the Services offered herein, you shall have to
                                authenticate your account in
                                the manner prescribed by us, which includes but may not be limited to, entering a
                                one-time
                                password (OTP) issued to you
                                by us.

                            </li>
                            <li>
                                Once you are registered as a User with the Partner in order to avail the Credit Line
                                being
                                provided by the Partner, you
                                shall be provided a Credible Finance Limit by the respective Partner. We shall assist
                                the
                                Partner by
                                providing the relevant
                                information to the Partner in order to enable it to complete the required processes.

                            </li>
                            <li>
                                Upon the completion of the registration and authentication processes, you may transact
                                using
                                the
                                App.

                            </li>
                            <li>
                                You hereby undertake and agree that:
                                <ol>
                                    <li>
                                        any pin or OTP or password or any unique number issued by us in relation to the
                                        authentication process shall be used
                                        solely by you and shall not be disclosed to any third-party, and
                                    </li>
                                    <li>
                                        you shall take all necessary steps, including deleting or destroying all records
                                        of
                                        the
                                        authentication process, to
                                        ensure that any unauthorized disclosure of the same is prevented.

                                    </li>
                                </ol>
                            </li>
                            <li>
                                You agree to immediately notify us of any unauthorized use of your authentication
                                process or
                                any
                                other breach of
                                security. You further acknowledge and agree that you shall be solely liable for any
                                losses,
                                disputes, costs, expenses,
                                fees, charges incurred by you, as well as for any fraudulent transactions undertaken on
                                a
                                Partner website, due to or
                                arising from your failure in complying with the T&Cs or any other reason, and neither us
                                nor
                                any
                                Partner(s) will be
                                liable for any costs, expenses, fees, charges, losses, damages or other liability
                                arising
                                from
                                your failure to comply
                                with the T&Cs or from any unauthorized access to or use of the pin or OTP or password or
                                any
                                such unique number issued
                                by us.

                            </li>
                            <li>
                                The User hereby acknowledges that other than the pin or OTP or password or any unique
                                number
                                issued to you by us in
                                relation to any authentication process for a particular service/transaction, we have no
                                obligation or liability to
                                verify the services/transactions entered into, authenticated and/or authorized by you.
                            </li>
                            <li>
                                In the event of any dispute between 2 (two) or more parties as to the ownership of a
                                particular
                                OTP or any other form of
                                authentication process, you hereby agree that we will be the sole expert of such
                                dispute,
                                and
                                that our decision (which
                                may include termination or suspension of any User account subject to dispute) shall be
                                final
                                and
                                binding on all parties
                                concerned.

                            </li>
                        </ol>

                    </li>
                    <br />
                    <li>
                        <p class="bold">REGISTRATION</p>
                        <ol>
                            <li>
                                You have to be registered as a User in order to avail the Credible Finance Limit, and by
                                accepting the
                                terms and
                                conditions
                                at the Partner website, you will be registered as a User with us on the App, and your
                                User
                                account will be
                                created on
                                the App as per the procedure laid down in the authentication process below.
                            </li>
                            <li>
                                Any additional T&Cs with respect to the creation and use of the User account, if any,
                                specified
                                on the App
                                shall also
                                be applicable to you along with these T&Cs.
                            </li>
                            <li>
                                For the purposes of the registration of your account with us, you agree and understand
                                that
                                your
                                personal
                                information,
                                including your phone number, PAN card, DOB, and other relevant details will be shared by
                                us
                                with
                                the
                                Partner, in order
                                to evaluate and register your account for availing the Services, and/or any other
                                service
                                provided by us, as
                                the case may be.
                            </li>
                            <li>
                                You shall ensure that:
                                <ol>
                                    <li>You furnish all information required for your availing of the Services as
                                        required
                                        by us
                                        from time
                                        to time; and</li>
                                    <li>All information provided by you is true, correct, accurate, and complete. In
                                        order
                                        to
                                        facilitate the
                                        smooth
                                        availing of the Services, you hereby agree to ensure that you shall immediately
                                        update
                                        the data
                                        provided by you
                                        in the event of any changes thereto.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Every time you wish to avail a service with a new Partner, you shall be prompted to
                                accept
                                the
                                respective
                                terms and
                                conditions applicable for the relevant Partner(s), as mentioned in Clause 2.4 above.
                                Your
                                acceptance of such
                                new terms
                                shall not mean the creation of a new user account with us. However, the terms accepted
                                by
                                you
                                for the
                                respective Partner
                                shall be in force in conjunction with these T&Cs.
                            </li>
                            <li>
                                You understand and accept that your acceptance of any terms for any products or
                                services,
                                whether with
                                third-parties or
                                other products and Services offered by us, shall not mean that such terms will
                                substitute
                                the
                                provisions and
                                terms
                                contained herein. The various terms accepted by you shall be in force for such duration
                                as
                                specified in the
                                respective terms.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">AUTHENTICATION</p>
                        <ol>
                            <li>
                                In order for you to register for availing the Services offered herein, you shall have to
                                authenticate your
                                account in
                                the manner prescribed by us, which includes but may not be limited to, entering a
                                one-time
                                password (OTP)
                                issued to you by us.
                            </li>
                            <li>
                                Once you are registered as a User with the Partner in order to avail the Credit Line
                                being
                                provided by the
                                Partner, you shall be provided
                                a Credible Finance Limit by the respective Partner. We shall assist the Partner by
                                providing
                                the
                                relevant
                                information to the Partner in order to
                                enable it to complete the required processes.
                            </li>
                            <li>
                                Upon the completion of the registration and authentication processes, you may transact
                                using
                                the
                                App.
                            </li>
                            <li>
                                You hereby undertake and agree that:
                                <ol>
                                    <li>any pin or OTP or password or any unique number issued by us in relation to the
                                        authentication
                                        process shall be used solely by
                                        you and shall not be disclosed to any third-party, and
                                    </li>
                                    <li>
                                        you shall take all necessary steps, including deleting or destroying all records
                                        of
                                        the
                                        authentication process, to ensure that any
                                        unauthorized disclosure of the same is prevented.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                You agree to immediately notify us of any unauthorized use of your authentication
                                process or
                                any
                                other
                                breach of security. You further acknowledge
                                and agree that you shall be solely liable for any losses, disputes, costs, expenses,
                                fees,
                                charges incurred
                                by you, as well as for any fraudulent
                                transactions undertaken on a Partner website, due to or arising from your failure in
                                complying
                                with the T&Cs
                                or any other reason, and neither us
                                nor any Partner(s) will be liable for any costs, expenses, fees, charges, losses,
                                damages,
                                or
                                other
                                liability arising from your failure to comply
                                with the T&Cs or from any unauthorized access to or use of the pin or OTP or password or
                                any
                                such unique
                                number issued by us.
                            </li>
                            <li>
                                The User hereby acknowledges that other than the pin or OTP or password or any unique
                                number
                                issued to you
                                by us in relation to any authentication
                                process for a particular service/transaction, we have no obligation or liability to
                                verify
                                the
                                services/transactions entered into, authenticated and/or
                                authorized by you.
                            </li>
                            <li>
                                In the event of any dispute between 2 (two) or more parties as to the ownership of a
                                particular
                                OTP or any
                                other form of authentication process, you
                                hereby agree that we will be the sole expert of such dispute, and that our decision
                                (which
                                may
                                include
                                termination or suspension of any User account
                                subject to dispute) shall be final and binding on all parties concerned.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">PAYMENT</p>
                        <ol>
                            <li>
                                Upon availing of the services from the Partner, you shall be permitted by such relevant
                                Partner
                                to make
                                payments for such services availed by way
                                of a deferred payment basis, or a lump-sum payment, as specified in the terms agreed to
                                by
                                the
                                User.
                            </li>
                            <li>
                                You hereby agree and acknowledge that the Credible Finance Limit as well as repayment
                                terms
                                may
                                be
                                varied or
                                modified from time to time by the concerned
                                Partner in consultation with us.
                            </li>
                            <li>
                                In case of you availing the Service, you shall be enabled to make the repayment of the
                                Credit
                                Line on a
                                deferred basis, in a lump sum amount, or
                                in installments, as offered by the Partner, subject to the Credible Finance Limit
                                specified
                                by
                                the
                                Partner in its
                                respective terms. You hereby consent
                                and agree to making the required payments in consideration of the Services provided, to
                                us,
                                being authorized
                                by the Partners to do so in the manner
                                specified by us at the time.
                            </li>
                            <li>
                                In case of you availing the pay later service, you hereby agree to make the repayment
                                directly
                                to the
                                concerned Partner in accordance with the
                                terms agreed upon between you and the Partner.
                            </li>
                            <li>
                                After you have completed the authentication process and avail of any Service, either we
                                or
                                the
                                concerned
                                Partner shall issue a single statement to
                                you with respect to the Services availed from the relevant Partner(s) during a specified
                                period.
                                The
                                statement shall specify the amounts required to be paid,
                                the timelines, and the manner in which the amount is to be paid by you. The statement
                                shall
                                be
                                issued by us
                                on a periodic basis in the form of an e-mail,
                                text message, notification on the App or any other medium.
                            </li>
                            <li>
                                You hereby undertake to pay the due amount specified in the statement in accordance with
                                the
                                timelines and
                                the manner specified in the statement. You hereby
                                acknowledge and agree that the date(s) specified in the statement for payment of the
                                statement
                                amount (each
                                a “Due Date”) is/are the date(s) by which the
                                statement amount (or part thereof, as specified in the statement) should be credited to
                                the
                                concerned
                                Partner’s bank account as specified in the terms
                                agreed by the User.
                            </li>
                            <li>
                                The User agrees that it shall not use any payment method other than the permitted
                                payment
                                methods notified
                                by us or the Partner in the terms agreed,
                                for the purpose of paying the statement amount and/or any other amounts towards services
                                availed
                                that are
                                due and payable by you to either us or the Partner.
                            </li>
                            <li>
                                You hereby agree and acknowledge that we shall not be liable under any circumstances
                                whatsoever,
                                and you
                                shall be solely liable under all circumstances, in relation to:
                                <ol>
                                    <li>
                                        non-delivery and/or delay in receipt of the statement as a result of any
                                        default,
                                        non-delivery or
                                        delay due to any third-party telecom service provider(s) and/or
                                        other service provider(s) used by us or the Partner(s); and
                                    </li>
                                    <li>
                                        any delay caused in payment of the statement amount due to the time taken for
                                        settlement
                                        and
                                        clearing of the statement amount from your bank account to
                                        the relevant Partner’s bank account.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">USER INFORMATION</p>
                        <ol>
                            <li>
                                You hereby understand that in order to avail the Services, we will share your phone
                                number
                                with
                                the
                                concerned Partner, along with other information
                                that may be available with us, as required/requested by the Partner. We will use such
                                information along with
                                your other information to, amongst others,
                                determine whether the required Services can be offered by the Partner to you.
                            </li>
                            <li>
                                You hereby agree that the personal information (including the name, address, contact
                                number
                                and
                                other
                                details) provided by you to us, and further shared by us
                                with the Partner at the time of registration, opening of the user account and/or
                                availing
                                the
                                Services and
                                at all other times for any other service, as may
                                be applicable (“Personal Information”), is and shall at all times remain true, accurate,
                                updated
                                and
                                complete. Personal Information shall also include your registered phone number.
                            </li>
                            <li>
                                If any Personal Information provided by or on behalf of you is found to be untrue,
                                inaccurate,
                                not current,
                                or complete, we shall be entitled at our sole discretion
                                to suspend or terminate your account and refuse any or all use of ours or the Partner’s
                                services, in the
                                present or future, as the case may be. We shall not be liable,
                                in any manner whatsoever, for any costs, expenses, loss or damage incurred by you or any
                                dispute
                                initiated
                                by or against you due to any untrue, inaccurate, non-current or incomplete Personal
                                Information.
                            </li>
                            <li>
                                You hereby agree that the Personal Information shall be collected, stored, analyzed and
                                used
                                by
                                us for the
                                purpose of providing you with efficient access to the App, and such other features,
                                products and/or services offered by us, including but not limited to the services
                                offered by
                                us
                                to the
                                Partner. You hereby grant your consent to us to further use and share your Personal
                                Information with our affiliates and/or the Partner and you hereby confirm that you do
                                not
                                have
                                any objection
                                to receiving promotional offers, deals, marketing material, promotional material
                                and any other form of communications from the Partner, us and/or ours or the Partners’
                                affiliates in such
                                form as may be determined by us or the Partner in our sole discretion. In addition,
                                you agree and understand that we will collect, receive and process your personal
                                information
                                in
                                accordance
                                with our Privacy Policy. By accepting the T&Cs, you hereby agree and accept our Privacy
                                Policy which may be updated by us from time to time.
                            </li>
                            <li>
                                You hereby agree that your Personal Information may be displayed on our App and/or the
                                Partner
                                website,
                                automatically or otherwise, upon your logging into your account and/or initiating any
                                service.
                                The Personal Information and other information required to provide you with the service
                                which is
                                readily
                                available on the App is viewable solely by you.
                            </li>
                            <li>
                                We may request and upon such request, you shall provide, from time to time, additional
                                information/documentation to:
                                <ol>
                                    <li>ensure your conformity with the requirements in accordance with applicable law;
                                        and
                                    </li>
                                    <li>verify the accuracy and completeness of the information provided by you.</li>
                                </ol>
                            </li>
                            <li>
                                You further acknowledge and understand that in order to provide the Services to you, we
                                may
                                fetch further
                                information about you from various third parties including our group companies and
                                affiliates.
                            </li>
                            <li>
                                Further, we also reserve the right to obtain information in relation to you from any
                                service
                                provider,
                                financial institution and/or governmental agency for the purposes of offering the
                                Services,
                                and you hereby acknowledge and agree that we shall be entitled to share any or all the
                                information so
                                obtained with any third parties as we see fit, to this end.
                            </li>
                            <li>
                                We shall ensure that our use and disclosure of your Personal Information shall at all
                                times,
                                be
                                in
                                accordance with applicable laws and our Privacy Policy.
                                In case of any discrepancy between the terms of these T&Cs and our Privacy Policy in
                                connection
                                with your
                                Personal Information, the terms of our Privacy Policy shall prevail.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">REPRESENTATIONS, WARRANTIES AND UNDERTAKING</p>
                        <ol>
                            <li>
                                The information and data contained in the App do not constitute an offer to buy or sell
                                or
                                solicitation of
                                an offer to buy or sell any Services in any jurisdiction other than India.
                            </li>
                            <li>
                                All information provided by you to us is accurate, correct and true.
                            </li>
                            <li>
                                You acknowledge and agree that we are entitled to remove any/all the information on the
                                App
                                that
                                is in
                                contravention of these T&Cs.
                            </li>
                            <li>
                                You hereby authorize us and/or the respective Partner, to make any enquiries with any
                                other
                                finance
                                company/bank/registered credit bureau regarding your credit history with them,
                                in connection with the Services under these T&Cs, as well as any other services that may
                                be
                                provided by us
                                at the time.
                            </li>
                            <li>
                                You agree to not engage in any activities pertaining to the Services that are contrary
                                to
                                any
                                applicable law
                                or regulation or the terms of any agreements you may have with us.
                            </li>
                            <li>
                                You agree not to:
                                <ol>
                                    <li>create multiple Accounts or fake Accounts;</li>
                                    <li>create Accounts fraudulently; and/or</li>
                                    <li>use the App for any unlawful or immoral purpose.</li>
                                </ol>
                            </li>
                            <li>
                                You understand that we do not provide any warranties for our Services and shall not be
                                made
                                liable for any
                                claims made by you or any third party. In this regard, you undertake to accept and be
                                solely
                                liable for the
                                Services availed by you from us. You further undertake to indemnify and hold harmless
                                us,
                                our
                                affiliates and
                                our respective directors, officers, employees, agents and representatives against all
                                damages
                                suffered or
                                losses incurred by us, arising due to any act, omission or claim initiated by you or any
                                third
                                party in
                                relation to the Services availed by you or in case of breach of these T&Cs or any
                                warranty
                                by
                                you.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">DELAYS IN PROCESSING</p>
                        <ol>
                            <li>
                                You understand that there might be inadvertent delays while the Services are being
                                rendered
                                to
                                you,
                                including but not limited to, at the time of making a purchase or making payment to the
                                Hospital. Please
                                note that any transaction may be canceled till the time it is confirmed by us.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">COLLECTION OF INFORMATION</p>
                        <ol>
                            <li>
                                In the course of availing Services, you authorize us, directly or through third parties,
                                to
                                make
                                any
                                inquiries we consider necessary to validate your identity and to collect information
                                about
                                you
                                in accordance
                                with our Privacy Policy. This may include asking you for further information that will
                                allow
                                us
                                to
                                reasonably identify you, requiring you to take steps to confirm ownership of your email
                                address
                                or financial
                                instruments, or verifying your information against third party databases or through
                                other
                                sources. You may
                                also be required to identify documents to help us validate your identity. In the event
                                we
                                are
                                unable to
                                obtain or verify your information, we reserve the right to close, suspend, or limit
                                access
                                to
                                your Account
                                and/or the Services rendered. By availing the Services, you also consent to enable us to
                                validate your
                                identity and to collect information about you in accordance with these T&Cs and our
                                Privacy
                                Policy.
                            </li>
                            <li>
                                You also provide your consent to us to use and share the information provided by you in
                                connection with the
                                Services or other services provided by us. You also agree and consent to us assessing
                                the
                                information, based
                                on your usage of the App.
                            </li>
                            <li>
                                You will be required to provide such documents as required by us.
                            </li>
                            <li>
                                You agree to provide only true, accurate, current and complete information about
                                yourself,
                                and
                                you agree not
                                to misrepresent your identity or your Account information in the course of availing
                                Services.
                                You further
                                agree to keep your Account information up to date and accurate.
                            </li>
                            <li>
                                The responsibility of providing correct information, details, including the contact
                                information,
                                amount, the
                                bank account details etc. lies solely with you. We shall not be responsible to verify
                                the
                                accuracy of the
                                information/details provided by you. In the event of any changes in the details or
                                information
                                provided by
                                you initially, you must inform us of such changes within a reasonable period of time. If
                                any
                                incorrect bank
                                account number is provided by you, any amount may be deposited/credited to the wrong
                                bank
                                account and there
                                is no guarantee of recovery of the same. We will not be responsible for any loss or
                                damage
                                that
                                may be
                                sustained by you on account of such error on your part.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">INTELLECTUAL PROPERTY POLICY</p>
                        <ol>
                            <li>
                                All of the content on the App, including, without limitation, all of the page headers,
                                images,
                                illustrations, graphics, audio clips, video clips or text, reports generated,
                                trademarks,
                                trade
                                names (“App
                                Content”), constitute our and our licensors’ intellectual property. Copyright laws in
                                all
                                applicable
                                jurisdictions protect the App and the App Content.
                            </li>
                            <li>
                                You may access the App, avail of the features and facilities and utilize the App Content
                                in
                                connection with
                                the subject matter hereof. You are not entitled to duplicate, distribute, create
                                derivative
                                works of,
                                display, or commercially exploit the App Content, features or facilities, directly or
                                indirectly, without
                                our prior written permission. If you would like to request permission to commercially
                                exploit
                                any particular
                                App Content, you could contact us in the manner provided for herein.
                            </li>
                            <li>
                                Credible Finance and its licensors, if any, are the sole owners of the underlying
                                software
                                and
                                source
                                code
                                associated with the App and all the trademarks, copyright and any other intellectual
                                property
                                rights of any
                                nature in the App.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">USER ACCOUNT, PASSCODE & SECURITY</p>
                        <ol>
                            <li>
                                You are mandatorily required to set a security passcode on the App once you are approved
                                for
                                the
                                Credible Finance
                                Limit. You are responsible for maintaining the confidentiality of the passcode and your
                                account
                                (“Account”)
                                and you are also fully responsible for all activities that occur under your passcode or
                                Account.
                                You agree
                                to:
                                <ol>
                                    <li>immediately notify us of any unauthorized use of your passcode or Account or any
                                        other
                                        breach of
                                        security; and</li>
                                    <li>ensure that you exit from your Account at the end of each session.</li>
                                </ol>
                                We cannot and will not be liable for any loss or damage arising from your failure to
                                comply
                                with
                                this
                                Clause.
                            </li>
                            <li>
                                You hereby acknowledge that the deletion of the App from the Device does not constitute
                                termination of your
                                Account and agree to undertake the process detailed herein in order to complete
                                de-registration.
                                If and when
                                you are desirous of having your name and other details removed from our records, upon
                                receiving
                                your written
                                request to that effect, we shall, subject to requirements under applicable law remove
                                and
                                delete
                                all such
                                information.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">USE OF APP – FEATURES AND FACILITIES</p>
                        <ol>
                            <li>
                                Through the App, we assist Users to gain access to pay-later services on both QR code
                                based
                                payments and on
                                online payments linked to the Credible Finance Limit.
                            </li>
                            <li>
                                We hereby grant to you a restricted, non-transferable license to download and use the
                                App on
                                a
                                Device, which
                                you own or control, to avail of the functions and features in accordance with these
                                T&Cs.
                            </li>
                            <li>
                                Your usage of the App will require you to provide to us certain personal and financial
                                information, which
                                shall be collected and maintained as per the terms laid down in our privacy policy
                                available
                                at
                                (“Privacy
                                Policy”).
                            </li>
                            <li>
                                We shall not mediate or attempt to get involved in and resolve any disputes or
                                disagreements
                                between you and
                                third party/ies.
                            </li>
                            <li>
                                We will use your name as per the records in NSDL as the name in the application.
                            </li>
                            <li>
                                We may update the App from time to time in order to, inter alia, increase our
                                efficiency,
                                optimize user
                                interface and add new features and/or facilities.
                            </li>
                            <li>
                                We allow our affiliates or third parties to collect certain information when you visit
                                our
                                App
                                through the
                                use of cookies or third-party web beacons to collect this information by those third
                                parties.
                            </li>
                            <li>
                                You are required to seek our permission in case you are writing something associated
                                with
                                the
                                App on social
                                media or otherwise. In the event you post any content on your own site or any other
                                third
                                party
                                sites about
                                us without our prior written permission, we may take strict actions against you.
                            </li>
                            <li>
                                You may choose to, or we may invite you to submit comments or ideas about the Services
                                rendered
                                under these
                                T&Cs, including without limitation about how to improve the Services. By submitting any
                                such
                                ideas, you
                                agree that your disclosure is gratuitous, unsolicited and without restriction and will
                                not
                                place
                                us or any
                                of our affiliates or subsidiaries, under any fiduciary or other obligation, and that we
                                will
                                be
                                free to use
                                the idea without any additional compensation to you, and/or to disclose the idea on a
                                non-confidential basis
                                or otherwise to anyone. You further acknowledge that, by acceptance of your submission,
                                we
                                do
                                not waive any
                                rights to use similar or related ideas previously known to, or developed by employees,
                                or
                                obtained from
                                sources other than you.
                            </li>
                            <li>
                                IN THE EVENT YOU HAVE ANY COMPLAINT AGAINST THE CONTENTS (OR ANY PORTION THEREOF),
                                INCLUDING
                                THAT THE
                                CONTENTS THEREIN ARE INCORRECT OR BREACH THE RIGHTS OF A THIRD PARTY, WE SHALL TAKE ALL
                                COMMERCIALLY
                                REASONABLE MEASURES TO ADDRESS THE SAME. HOWEVER, OUR SOLE OBLIGATION IN THIS REGARD
                                SHALL
                                BE TO
                                REMOVE SUCH
                                CONTENT FROM THE APP; AND THE USER SHALL HAVE NO FURTHER CAUSE OF ACTION AGAINST US.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">SERVICE PROVIDERS</p>
                        <ol>
                            <li>
                                We may employ third-party service providers and individuals for the following reasons:
                                <ol>
                                    <li>To facilitate our Service;</li>
                                    <li>To provide the Service on our behalf;</li>
                                    <li>To perform Service-related services; or</li>
                                    <li>To assist us in analyzing how our Service is used.</li>
                                </ol>
                            </li>
                            <li>
                                You acknowledge that the third-party service provider/agent/agencies will have access to
                                your
                                personal
                                information on a need basis to assist us in rendering service, and the service providers
                                are
                                restricted from
                                using the same for any other reason. The third-party service providers are obligated not
                                to
                                disclose or use
                                the information for any other purpose.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN INDIA</p>
                        <ol>
                            <li>
                                These T&Cs are intended for Users within the territory of India and govern your rights
                                as
                                per
                                applicable law
                                within the territory of India. However, in the event you fall under a jurisdiction
                                outside
                                the
                                purview of
                                Indian law, we will not be liable for any claim, action and/or right initiated/exercised
                                by
                                you
                                as per the
                                extant laws of that jurisdiction. Therefore, we request you to kindly use the App
                                accordingly.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">CUSTOMER COMMUNICATIONS</p>
                        <ol>
                            <li>
                                Accepting these T&Cs implies your express consent to be contacted by us, our agents,
                                representatives,
                                affiliates, or anyone calling on our behalf at any contact number, or physical or
                                electronic
                                address
                                provided by you while registering your Account. You further agree to us contacting you
                                in
                                any
                                manner,
                                including without limitation, SMS messages (including text messages), WhatsApp, calls
                                using
                                pre-recorded
                                messages or artificial voice, calls and messages delivered using auto telephone dialing
                                system
                                or an
                                automatic texting system, and notifications sent via the App. Automated messages may be
                                played
                                when the
                                telephone is answered, whether by you or someone else. In the event that an agent or
                                representative calls,
                                he or she may also leave a message on your answering machine, voice mail, or send one
                                via
                                SMS.
                            </li>
                            <li>
                                You certify, warrant and represent that the telephone numbers and/or email addresses and
                                any
                                other
                                information that you have provided to us are your own and not someone else’s and are
                                true,
                                accurate, current
                                and complete. You represent that you are permitted to receive calls at each of the
                                telephone
                                numbers you
                                have provided to us and emails at each of the email addresses you have provided us. You
                                agree to
                                notify us
                                whenever you stop using a particular telephone number(s) and/or email address(es).
                            </li>
                            <li>
                                In relation to the unsolicited communication referred in “National Do Not Call Registry”
                                as
                                laid
                                down by
                                Telecom Regulatory Authority of India, you confirm that such laws will not be applicable
                                for
                                such
                                communication/calls/SMS/messages through WhatsApp messenger application received from
                                us,
                                our
                                employees,
                                agents and/or associates.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">SYSTEM REQUIREMENTS</p>
                        <ol>
                            <li>
                                In order to use the App, you are required to have a compatible Device with the following
                                minimum
                                specifications: Android Operating System version 4.1 (SDK level 16) or higher- or iOS
                                9.0 or
                                higher.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">USER GUIDELINES</p>
                        <ol>
                            <li>
                                In consideration of our granting you the rights hereunder, you hereby agree not to use
                                the
                                App
                                for any
                                purpose that is unlawful under any applicable laws and/or in violation of the terms of
                                these
                                T&Cs and our
                                Privacy Policy. You shall not use the App in any manner that could damage, disable,
                                overburden,
                                or impair
                                our server, or any network(s) connected to any server, or interfere with any other
                                party’s
                                use
                                and enjoyment
                                of the App. You shall not attempt to gain unauthorized access to any functions and
                                features,
                                other user
                                accounts, computer systems or networks connected to any server, in any manner,
                                including,
                                through hacking,
                                password mining or any other means. You shall not obtain or attempt to obtain any
                                materials
                                or
                                information
                                through any means which is not intentionally made available through the App.
                            </li>
                            <li>
                                The App is made available to you for your own personal and non-commercial use alone. You
                                shall
                                not allow
                                third parties to:
                                <ol>
                                    <li>make and/or distribute copies of the App or any deliverable generated by the
                                        App;
                                    </li>
                                    <li>attempt to copy, reproduce, alter, modify and/or reverse engineer the App;
                                        and/or
                                    </li>
                                    <li>create derivative works of the App.</li>
                                </ol>
                            </li>
                            <li>
                                You accept that any and all operations emanating from your Device shall be assumed to
                                have
                                been
                                initiated by
                                you.
                            </li>
                            <li>
                                You shall not copy, reproduce, distribute, or create derivative works of our content
                                that is
                                available on
                                the App. Also, you shall not reverse engineer or reverse compile our technology that is
                                available on the
                                App, including, without limitation, such Java Applet, as may be associated with the App
                                from
                                time to time.
                            </li>
                            <li>
                                You shall request us to block the Account and change the passcode/password immediately
                                for
                                the
                                Account, if
                                your Device has been lost or stolen.
                            </li>
                            <li>
                                You are responsible for any and all activities that occur in your Account. You agree to
                                notify
                                us
                                immediately of any unauthorized use of the Account or any other breach of security. We
                                shall
                                not
                                be liable
                                for any loss to you or your organization owing to negligent actions or a failure on your
                                part to
                                inform us,
                                within a reasonable time, about loss or theft of your Device and/or any unauthorized
                                access
                                in
                                your Account,
                                either with or without your knowledge.
                            </li>
                            <li>
                                You shall be liable for losses incurred by us or any other party due to a third party’s
                                use
                                of
                                the Account.
                                You shall not use any other person’s account at any time, without our permission and the
                                permission of the
                                account holder.
                            </li>
                            <li>
                                We shall make all reasonable efforts to ensure that your information is kept
                                confidential.
                                However, we shall
                                not be responsible for any disclosure or leakage of confidential information and/or loss
                                or
                                damage of the
                                Device due to theft, negligence or failure on your part to practice safe computing.
                            </li>
                            <li>
                                You shall ensure that while using the functions and features, all prevailing and
                                applicable
                                laws, rules and
                                regulations, shall at all times, be strictly complied with by you and we shall not be
                                liable
                                in
                                any manner
                                whatsoever for default of any nature, by you, regarding the same.
                            </li>
                            <li>
                                You understand and acknowledge that upon using the App, you authorize us to access third
                                party
                                sites
                                designated by you, on your behalf, to retrieve such information requested by you, and to
                                register for
                                accounts requested by you. For all purposes hereof, you hereby grant us limited powers
                                and
                                hereby authorize
                                us with the full power of substitution and resubstitution, for you and in your name,
                                place
                                and
                                stead, in any
                                and all capacities, to access third party internet sites, servers or documents, retrieve
                                information, and
                                use your information, all as described above, and empower us with the full power and
                                authority
                                to do and
                                perform each and every act and thing requisite and necessary to be done in connection
                                with
                                the
                                Services and
                                facilities available on the App, as fully to all intents and purposes as you might or
                                could
                                do
                                in person.
                                YOU ACKNOWLEDGE AND AGREE THAT WHEN WE ACCESS AND RETRIEVE INFORMATION FROM THIRD PARTY
                                SITES,
                                WE ARE ACTING
                                AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that such
                                third
                                parties shall be
                                entitled to rely on the foregoing authorization and agency granted by you.
                            </li>
                            <li>
                                You hereby acknowledge and agree that we shall not be liable for failure of any
                                transaction
                                undertaken for
                                any reason whatsoever including but not limited to deficiency of service and/or products
                                delivered as well
                                as technical errors. You further acknowledge that we shall not be responsible in any
                                manner
                                whatsoever, for
                                any loss incurred by you for a failed/ incomplete transaction undertaken by you using
                                our
                                Services.
                            </li>
                            <li>
                                You hereby agree and express your voluntary, unequivocal and informed consent to your
                                personal
                                data or
                                financial information (including such data as may be designated as ‘personally
                                identifiable
                                data’ under the
                                Information Technology (Reasonable Security Practices and Procedures and Sensitive
                                Personal
                                Data
                                or
                                Information) Rules, 2011 or other applicable law), being processed, disclosed and/or
                                transferred
                                by us to
                                third-party entities.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">USE OF APP – FEATURES AND FACILITIES</p>
                        <ol>
                            <li>
                                Through the App, we assist Users to gain access to pay-later services on both QR code
                                based
                                payments and on
                                online payments linked to the Credible Finance Limit.
                            </li>
                            <li>
                                We hereby grant to you a restricted, non-transferable license to download and use the
                                App on
                                a
                                Device, which
                                you own or control, to avail of the functions and features in accordance with these
                                T&Cs.
                            </li>
                            <li>
                                Your usage of the App will require you to provide to us certain personal and financial
                                information, which
                                shall be collected and maintained as per the terms laid down in our privacy policy
                                available
                                at
                                (“Privacy
                                Policy”).
                            </li>
                            <li>
                                We shall not mediate or attempt to get involved in and resolve any disputes or
                                disagreements
                                between you and
                                third party/ies.
                            </li>
                            <li>
                                We will use your name as per the records in NSDL as the name in the application.
                            </li>
                            <li>
                                We may update the App from time to time in order to, inter alia, increase our
                                efficiency,
                                optimize user
                                interface and add new features and/or facilities.
                            </li>
                            <li>
                                We allow our affiliates or third parties to collect certain information when you visit
                                our
                                App
                                through the
                                use of cookies or third-party web beacons to collect this information by those third
                                parties.
                            </li>
                            <li>
                                You are required to seek our permission in case you are writing something associated
                                with
                                the
                                App on social
                                media or otherwise. In the event you post any content on your own site or any other
                                third
                                party
                                sites about
                                us without our prior written permission, we may take strict actions against you.
                            </li>
                            <li>
                                You may choose to, or we may invite you to submit comments or ideas about the Services
                                rendered
                                under these
                                T&Cs, including without limitation about how to improve the Services. By submitting any
                                such
                                ideas, you
                                agree that your disclosure is gratuitous, unsolicited and without restriction and will
                                not
                                place
                                us or any
                                of our affiliates or subsidiaries, under any fiduciary or other obligation, and that we
                                will
                                be
                                free to use
                                the idea without any additional compensation to you, and/or to disclose the idea on a
                                non-confidential basis
                                or otherwise to anyone. You further acknowledge that, by acceptance of your submission,
                                we
                                do
                                not waive any
                                rights to use similar or related ideas previously known to, or developed by employees,
                                or
                                obtained from
                                sources other than you.
                            </li>
                            <li>
                                IN THE EVENT YOU HAVE ANY COMPLAINT AGAINST THE CONTENTS (OR ANY PORTION THEREOF),
                                INCLUDING
                                THAT THE
                                CONTENTS THEREIN ARE INCORRECT OR BREACH THE RIGHTS OF A THIRD PARTY, WE SHALL TAKE ALL
                                COMMERCIALLY
                                REASONABLE MEASURES TO ADDRESS THE SAME. HOWEVER, OUR SOLE OBLIGATION IN THIS REGARD
                                SHALL
                                BE TO
                                REMOVE SUCH
                                CONTENT FROM THE APP; AND THE USER SHALL HAVE NO FURTHER CAUSE OF ACTION AGAINST US.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">SERVICE PROVIDERS</p>
                        <ol>
                            <li>
                                We may employ third-party service providers and individuals for the following reasons:
                                <ol>
                                    <li>To facilitate our Service;</li>
                                    <li>To provide the Service on our behalf;</li>
                                    <li>To perform Service-related services; or</li>
                                    <li>To assist us in analyzing how our Service is used.</li>
                                </ol>
                            </li>
                            <li>
                                You acknowledge that the third-party service provider/agent/agencies will have access to
                                your
                                personal
                                information on a need basis to assist us in rendering service, and the service providers
                                are
                                restricted from
                                using the same for any other reason. The third-party service providers are obligated not
                                to
                                disclose or use
                                the information for any other purpose.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN INDIA</p>
                        <ol>
                            <li>
                                These T&Cs are intended for Users within the territory of India and govern your rights
                                as
                                per
                                applicable law
                                within the territory of India. However, in the event you fall under a jurisdiction
                                outside
                                the
                                purview of
                                Indian law, we will not be liable for any claim, action and/or right initiated/exercised
                                by
                                you
                                as per the
                                extant laws of that jurisdiction. Therefore, we request you to kindly use the App
                                accordingly.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">CUSTOMER COMMUNICATIONS</p>
                        <ol>
                            <li>
                                Accepting these T&Cs implies your express consent to be contacted by us, our agents,
                                representatives,
                                affiliates, or anyone calling on our behalf at any contact number, or physical or
                                electronic
                                address
                                provided by you while registering your Account. You further agree to us contacting you
                                in
                                any
                                manner,
                                including without limitation, SMS messages (including text messages), WhatsApp, calls
                                using
                                pre-recorded
                                messages or artificial voice, calls and messages delivered using auto telephone dialing
                                system
                                or an
                                automatic texting system, and notifications sent via the App. Automated messages may be
                                played
                                when the
                                telephone is answered, whether by you or someone else. In the event that an agent or
                                representative calls,
                                he or she may also leave a message on your answering machine, voice mail, or send one
                                via
                                SMS.
                            </li>
                            <li>
                                You certify, warrant and represent that the telephone numbers and/or email addresses and
                                any
                                other
                                information that you have provided to us are your own and not someone else’s and are
                                true,
                                accurate, current
                                and complete. You represent that you are permitted to receive calls at each of the
                                telephone
                                numbers you
                                have provided to us and emails at each of the email addresses you have provided us. You
                                agree to
                                notify us
                                whenever you stop using a particular telephone number(s) and/or email address(es).
                            </li>
                            <li>
                                In relation to the unsolicited communication referred in “National Do Not Call Registry”
                                as
                                laid
                                down by
                                Telecom Regulatory Authority of India, you confirm that such laws will not be applicable
                                for
                                such
                                communication/calls/SMS/messages through WhatsApp messenger application received from
                                us,
                                our
                                employees,
                                agents and/or associates.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">SYSTEM REQUIREMENTS</p>
                        <ol>
                            <li>
                                In order to use the App, you are required to have a compatible Device with the following
                                minimum
                                specifications: Android Operating System version 4.1 (SDK level 16) or higher- or iOS
                                9.0 or
                                higher.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">USER GUIDELINES</p>
                        <ol>
                            <li>
                                In consideration of our granting you the rights hereunder, you hereby agree not to use
                                the
                                App
                                for any
                                purpose that is unlawful under any applicable laws and/or in violation of the terms of
                                these
                                T&Cs and our
                                Privacy Policy. You shall not use the App in any manner that could damage, disable,
                                overburden,
                                or impair
                                our server, or any network(s) connected to any server, or interfere with any other
                                party’s
                                use
                                and enjoyment
                                of the App. You shall not attempt to gain unauthorized access to any functions and
                                features,
                                other user
                                accounts, computer systems or networks connected to any server, in any manner,
                                including,
                                through hacking,
                                password mining or any other means. You shall not obtain or attempt to obtain any
                                materials
                                or
                                information
                                through any means which is not intentionally made available through the App.
                            </li>
                            <li>
                                The App is made available to you for your own personal and non-commercial use alone. You
                                shall
                                not allow
                                third parties to:
                                <ol>
                                    <li>make and/or distribute copies of the App or any deliverable generated by the
                                        App;
                                    </li>
                                    <li>attempt to copy, reproduce, alter, modify and/or reverse engineer the App;
                                        and/or
                                    </li>
                                    <li>create derivative works of the App.</li>
                                </ol>
                            </li>
                            <li>
                                You accept that any and all operations emanating from your Device shall be assumed to
                                have
                                been
                                initiated by
                                you.
                            </li>
                            <li>
                                You shall not copy, reproduce, distribute, or create derivative works of our content
                                that is
                                available on
                                the App. Also, you shall not reverse engineer or reverse compile our technology that is
                                available on the
                                App, including, without limitation, such Java Applet, as may be associated with the App
                                from
                                time to time.
                            </li>
                            <li>
                                You shall request us to block the Account and change the passcode/password immediately
                                for
                                the
                                Account, if
                                your Device has been lost or stolen.
                            </li>
                            <li>
                                You are responsible for any and all activities that occur in your Account. You agree to
                                notify
                                us
                                immediately of any unauthorized use of the Account or any other breach of security. We
                                shall
                                not
                                be liable
                                for any loss to you or your organization owing to negligent actions or a failure on your
                                part to
                                inform us,
                                within a reasonable time, about loss or theft of your Device and/or any unauthorized
                                access
                                in
                                your Account,
                                either with or without your knowledge.
                            </li>
                            <li>
                                You shall be liable for losses incurred by us or any other party due to a third party’s
                                use
                                of
                                the Account.
                                You shall not use any other person’s account at any time, without our permission and the
                                permission of the
                                account holder.
                            </li>
                            <li>
                                We shall make all reasonable efforts to ensure that your information is kept
                                confidential.
                                However, we shall
                                not be responsible for any disclosure or leakage of confidential information and/or loss
                                or
                                damage of the
                                Device due to theft, negligence or failure on your part to practice safe computing.
                            </li>
                            <li>
                                You shall ensure that while using the functions and features, all prevailing and
                                applicable
                                laws, rules and
                                regulations, shall at all times, be strictly complied with by you and we shall not be
                                liable
                                in
                                any manner
                                whatsoever for default of any nature, by you, regarding the same.
                            </li>
                            <li>
                                You understand and acknowledge that upon using the App, you authorize us to access third
                                party
                                sites
                                designated by you, on your behalf, to retrieve such information requested by you, and to
                                register for
                                accounts requested by you. For all purposes hereof, you hereby grant us limited powers
                                and
                                hereby authorize
                                us with the full power of substitution and resubstitution, for you and in your name,
                                place
                                and
                                stead, in any
                                and all capacities, to access third party internet sites, servers or documents, retrieve
                                information, and
                                use your information, all as described above, and empower us with the full power and
                                authority
                                to do and
                                perform each and every act and thing requisite and necessary to be done in connection
                                with
                                the
                                Services and
                                facilities available on the App, as fully to all intents and purposes as you might or
                                could
                                do
                                in person.
                                YOU ACKNOWLEDGE AND AGREE THAT WHEN WE ACCESS AND RETRIEVE INFORMATION FROM THIRD PARTY
                                SITES,
                                WE ARE ACTING
                                AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that such
                                third
                                parties shall be
                                entitled to rely on the foregoing authorization and agency granted by you.
                            </li>
                            <li>
                                You hereby acknowledge and agree that we shall not be liable for failure of any
                                transaction
                                undertaken for
                                any reason whatsoever including but not limited to deficiency of service and/or products
                                delivered as well
                                as technical errors. You further acknowledge that we shall not be responsible in any
                                manner
                                whatsoever, for
                                any loss incurred by you for a failed/ incomplete transaction undertaken by you using
                                our
                                Services.
                            </li>
                            <li>
                                You hereby agree and express your voluntary, unequivocal and informed consent to your
                                personal
                                data or
                                financial information (including such data as may be designated as ‘personally
                                identifiable
                                data’ under the
                                Information Technology (Reasonable Security Practices and Procedures and Sensitive
                                Personal
                                Data
                                or
                                Information) Rules, 2011 or other applicable law), being processed, disclosed and/or
                                transferred
                                by us to
                                third-party entities.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">VALIDITY OF T&Cs</p>
                        <ol>
                            <li>
                                These T&Cs shall apply when you complete the authentication process and create an
                                Account
                                and
                                shall remain
                                valid and binding on you for so long as you maintain the Account and avail Services.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">SECURITY</p>
                        <ol>
                            <li>
                                You are prohibited from violating or attempting to violate the security of the App,
                                including,
                                without
                                limitation:
                                <ol>
                                    <li>accessing data not intended for you or logging into an account which you are not
                                        authorized to
                                        access;</li>
                                    <li>attempting to probe, scan or test the vulnerability of a system or network or to
                                        breach
                                        security or
                                        authentication measures without proper authorization; or</li>
                                    <li>interfering with service to any user, host, or network.</li>
                                </ol>
                            </li>
                            <li>
                                You shall not misuse this App by intentionally introducing viruses, trojans, worms,
                                logic
                                bombs
                                or other
                                materials that are malicious or technologically harmful (together “Viruses”). You will
                                not
                                attempt to gain
                                unauthorized access to the App, or any server, computer or database connected to the
                                App.
                                You
                                will not
                                attack this App via a denial-of-service attack. Further, you shall not interfere with or
                                circumvent any
                                security feature of the App or any feature that restricts or enforces limitations on use
                                of
                                or
                                access to the
                                App, such as probing or scanning the vulnerability of any system, network or breach.
                            </li>
                            <li>
                                Should you breach the provisions of this Clause, you will be liable to be prosecuted
                                under
                                the
                                Information
                                Technology Act, 2000 and other applicable statutes. We will immediately report such
                                breach
                                to
                                the relevant
                                law enforcement authorities and will co-operate with such authorities by disclosing your
                                identity to them.
                                In the event of such a breach, your rights to use this App will cease immediately.
                            </li>
                            <li>
                                We will not be liable for any loss or damage caused by a denial-of-service attack or
                                Viruses
                                that may infect
                                your computer equipment, computer programs, data or other proprietary material due to
                                your
                                use
                                of this App
                                or to your downloading of any material posted on it.
                            </li>
                            <li>
                                You agree to immediately report to us all incidents involving suspected or actual
                                unauthorized
                                access,
                                disclosure, alteration, loss, damage, or destruction of data.
                            </li>
                            <li>
                                You shall not interfere with or disrupt (or attempt to interfere with or disrupt) access
                                and
                                enjoyment of
                                the App by other users or any host or network, including, without limitation, creating
                                or
                                transmitting
                                unwanted electronic communications such as “spam” to other users, overloading, flooding
                                or
                                mail-bombing the
                                App, or scripting the creation of content in such a manner as to interfere with or
                                create an
                                undue burden on
                                the App.
                            </li>
                            <li>
                                All actions performed by any person using your account and password shall be deemed to
                                have
                                been
                                committed
                                by you and you shall be liable for the same. We reserve the right to suspend/terminate
                                your
                                account at any
                                time if it is found that you have been sharing the password with any unauthorized user.
                            </li>
                            <li>
                                In order to reduce the risk of unauthorized access, a user is logged out from the
                                account
                                and
                                will have to
                                enter his details and login afresh after 3 (three) consecutive incorrect login attempts.
                                In
                                the
                                event of
                                such lockout, you can email the administrator at hello&#64;credible.finance and the
                                account
                                shall
                                be
                                unlocked
                                upon receipt of your email. You can also request for a password reset on our website
                                through
                                the
                                ‘forgot
                                password’ option on our App.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">MONITORING</p>
                        <ol>
                            <li>
                                All electronic communications and content presented and/or passed to us, including that
                                presented and/or
                                passed from remote access connections, may be monitored, examined, saved, read,
                                transcribed,
                                stored, or
                                retransmitted in the course of daily operations by any duly authorized employee or agent
                                of
                                ours
                                in the
                                exercise of their duties, or by law enforcement authorities who may be assisting us in
                                investigating
                                possible contravention/non-compliance with applicable law. Electronic communications and
                                content
                                may be
                                examined by automated means. Further, we have the right to reject, at our sole
                                discretion,
                                from
                                the App any
                                electronic communications or content deemed not to be in compliance with our corporate
                                policies
                                and
                                procedures.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">GOVERNING LAW AND JURISDICTION</p>
                        <ol>
                            <li>
                                These T&Cs (and by extension, the Privacy Policy) are governed and construed in
                                accordance
                                with
                                Indian law.
                                By using the App, you hereby irrevocably consent to the exclusive jurisdiction and venue
                                of
                                courts in
                                Bangalore, Karnataka, India, in the event of any disputes arising out of or in relation
                                to
                                your
                                access to
                                and use of the App.
                            </li>
                        </ol>
                    </li>

                    <br />
                    <li>
                        <p class="bold">CONTACT INFORMATION</p>
                        <ol>
                            <li>
                                If you have any questions or concerns regarding the Terms of service or Privacy Policy,
                                please
                                feel free to
                                contact us at the following email address: hello&#64;credible.finance or at the contact
                                details
                                published on the
                                App.
                            </li>
                        </ol>
                    </li>

                </ol>
            </div>
        </div>
    </div>
</div>