import {
    Component, HostListener, Inject, OnDestroy, OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { nullChecker } from '../helpers/nullChecker';
import { webUrl } from '../app.config';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {
    width: any;
    window: Window;
    currentTab = 'pools';
    tabs = [{
        name: 'Pools',
        key: 'pools'
    }, {
        name: 'Assets',
        key: 'assets'
    }];
    platformId: object = {};

    chartDataInvestment: Array<any> = [];
    chartDataDrawdown: Array<any> = [];
    activites: Array<any> = [];
    activity_page = 0;
    activity_limit = 5;
    pools_page = 0;
    pools_limit = 5;

    total_balance: number = 0;
    active_pools: Array<{
        pool_id: string,
        pool_name: string,
        user_balance: number,
        pool_currency: string,
        pool_balance: number,
        pool_logo: string,
        animated_weight: number,
        actual_weight: number,
        category_id: string
    }> = [];

    assets: Array<any> = [];
    assets_page = 0;
    assets_limit = 5;

    setIntervalIds: any = {};
    animation_duration: number = 1500;
    animation_steps_count: number = 15;

    recentTransactions: Array<any> = [];
    recentTransactionsPage = 0;
    recentTransactionsLimit = 5;

    unclaimed_interest_usd : number = 0;
    unclaimed_interest_updated_at : number = new Date().getTime();
    unclaimed_interest_loaded: boolean = false;
    unclaimed_interest_tooltip_text: string = '';

    total_points: number = 0;

    chart_loaded: boolean = false;
    invite_code : any;
    invitation_link:string = '';
    @ViewChild(LineChartComponent) child!: LineChartComponent;
    trimInvitationLink: boolean = false;

    portfolioOptions: Array<IDropdown> = [{
    //     value: '1w',
    //     label: '1 Week',
    //     optionEnabled: true
    // }, {
        value: '1d',
        label: '1 Day',
        optionEnabled: true
    // }, {
    //     value: '12h',
    //     label: '12 Hrs',
    //     optionEnabled: true
    // }, {
    //     value: '6h',
    //     label: '6 Hrs',
    //     optionEnabled: true
    }];

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService,
        private route: ActivatedRoute, private router: Router, @Inject(PLATFORM_ID) platformId: object) {
        this.window = getWindow();
        this.platformId = platformId;
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.width = this.window.innerWidth;

        if (this.width < 768)
            this.trimInvitationLink = true;

        if (this.httpService.user.wallet_address !== '') {
            this.getLendUserActivity();
            this.getLendUserPortfolioCharts();
            this.getLendUserRecentTransactions();
            this.getLendUserPoolStats();
            this.getLendUserAssets();
            this.updateUserUnclaimedInterest();
            this.getLoyaltyPoints();
        }

        else {
            this.chartDataInvestment = this.getDummyChartData();
            this.chart_loaded = true;
        }

        if (isPlatformBrowser(this.platformId))
            setTimeout(() => this.animateWeights(), 1000);

        this.invite_code = this.httpService.user?.referral_code;
        this.invitation_link = `${webUrl}/invite/${this.invite_code}`;
        this.checkDefaultTab();
    }

    checkDefaultTab() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.key === tab))
                    this.currentTab = tab;
            }
        });
    }

    getDummyChartData() {
        const data = [];

        let current_time = new Date().setSeconds(0, 0);

        for (let i = 0; i < 30; i++) {
            data.push({
                x: current_time,
                y: 0
            });

            current_time = new Date(current_time - (24 * 60 * 60 * 1000)).setSeconds(0, 0);
        }

        return data;
    }

    getLendUserPortfolioCharts() {
        this.httpService.getLendUserPortfolioCharts().subscribe(res => {
            this.chartDataInvestment = !nullChecker(res.data.tvlvsdate_30) ? res.data.tvlvsdate_30 : this.getDummyChartData();

            this.chart_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getLendUserPoolStats() {
        this.httpService.getLendUserPoolStats(this.pools_page, this.pools_limit).subscribe(res => {
            const data = res.data.records;

            this.total_balance = res.data.balance;

            this.active_pools = data.map((item: any) => {
                return {
                    pool_id: item.pool_id,
                    pool_name: item.pool_name,
                    user_balance: item.investment_amount - item.withdrawal_amount,
                    pool_currency: 'usdc',
                    pool_balance: item.pool_tvl,
                    pool_logo: item.pool_logo,
                    animated_weight: 0,
                    actual_weight: item.weightage,
                    category_id: item.category_id
                };
            });
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    animateWeights() {
        const per_step_duration = this.animation_duration / this.animation_steps_count;

        let i = 0;

        this.active_pools.forEach(pool => {
            const difference = pool.actual_weight - pool.animated_weight;

            const step_size = difference / this.animation_steps_count;

            this.setIntervalIds[pool.pool_id] = setInterval(() => {
                if (pool.animated_weight >= pool.actual_weight) {
                    clearInterval(this.setIntervalIds[pool.pool_id]);

                    return;
                }

                if (pool.animated_weight + step_size > pool.actual_weight)
                    pool.animated_weight = pool.actual_weight;

                else
                    pool.animated_weight = pool.animated_weight + step_size;

                console.log(++i, pool.animated_weight);
            }, per_step_duration);
        });
    }

    getLendUserActivity() {
        this.httpService.getLendUserActivity(this.activity_page, this.activity_limit).subscribe(res => {
            this.activites = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getLendUserAssets() {
        this.httpService.getLendUserAssets(this.assets_page, this.assets_limit).subscribe(res => {
            this.assets = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    } 

    getLendUserRecentTransactions() {
        this.httpService.getLendUserRecentTransactions(this.recentTransactionsPage, this.recentTransactionsLimit).subscribe(res => {
            this.recentTransactions = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    updateUserUnclaimedInterest() {
        this.unclaimed_interest_loaded = false;

        this.httpService.updateLendUserUnclaimedInterest({}).subscribe((res: any) => {
            this.unclaimed_interest_usd = res.data.unclaimed_interest_usd;
            this.unclaimed_interest_updated_at = res.data.updated;

            this.unclaimed_interest_tooltip_text = `Last updated at ${new Date(this.unclaimed_interest_updated_at).toLocaleString()}`;

            this.unclaimed_interest_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error('updateUserUnclaimedInterest error');
            console.error(err);
        });
    }

    getLoyaltyPoints() {
        this.httpService.getLoyaltyPoints().subscribe((res) => {
            this.total_points = res.data.total_points;
        }, (err: HttpErrorResponse) => {
            console.error('getLoyaltyPoints error');
            console.error(err);
        });
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    redirectToUrl(url: string) {
        this.router.navigate([ url ]);
    }

    redirectionPool(category_id: string, pool_id: string) {
        if (category_id !== 'private-equity')
            this.router.navigate([ `/liquidity/${category_id}/${pool_id}` ]);
    }

    ngOnDestroy(): void {
        for (const key in this.setIntervalIds)
            clearInterval(this.setIntervalIds[key]);
    }
}
