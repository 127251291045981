<div class="app-homepage">
    <div class="container-fluid">
        <div class="section-1">
            <div class="background">
                <h1 class="title">Liquidity Protocol</h1>
                <h1 class="title">for Tokenized RWAs</h1>
            </div>

            <div class="row">
                <div class="stats">
                    <div class="stat-container">
                        <span class="stat-value">${{ stats.tvl_usd | number : "0.0-2" }}</span>
                        <span class="stat-heading">of liquidity locked across USDC, SOL & USDT</span>
                    </div>

                    <div class="control-containers">
                        <div class="control-title-containers">
                            <div class="control-title-container" *ngFor="let control of controls"
                                (click)="selectedControl(control.index)" [ngClass]="{
                                    'control-active':
                                        control.index === selectedControlIndex
                                }">
                                <span class="control-title">
                                    {{ control.title }}
                                </span>
                                <div class="control-line" *ngIf="
                                        control.index === selectedControlIndex
                                    ">
                                    <div class="filled" [ngStyle]="{
                                            width: animationWidth + '%'
                                        }"></div>
                                </div>
                            </div>
                        </div>
                        <div class="control-description-containers">
                            <div class="control-description-container" *ngFor="let control of controls" [ngClass]="{
                                    'control-active':
                                        control.index === selectedControlIndex
                                }">
                                <span class="control-description" *ngIf="
                                        control.index === selectedControlIndex
                                    ">
                                    {{ control.description }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-2">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="d-grid">
                        <span class="title">Gateway to Liquidity</span>
                        <span class="sub-title">Tokenization with proof of liquidity to maximize
                            the tokenized assets potential in the Web3
                            ecosystem.
                        </span>
                    </div>
                </div>
            </div>

            <div class="background-mobile"></div>

            <div class="home-cards">
                <div class="home-card" *ngFor="let feature of features"
                    (click)="redirectToInternalUrl(feature.redirect)">
                    <div class="card-img">
                        <img [src]="feature.icon" [alt]="feature.title" class="img-fluid" />
                        <span class="click-to-action">Get started<i class="fa-solid fa-arrow-right"></i></span>
                    </div>

                    <div class="card-information">
                        <span class="home-card-title">{{ feature.title }}</span>
                        <p class="home-card-sub-title">
                            {{ feature.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-4">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title">A full ecosystem powered</h1>
                    <h1 class="title">by the Credible</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <span class="sub-title">Experience a thriving community designed for seamless
                        interaction and growth, offering a full range of DeFi
                        services to meet your financial needs.</span>
                </div>
            </div>

            <div class="row mt-4">
                <div class="d-flex justify-content-center">
                    <cf-button label="Join Ecosystem" type="outline" (click)="redirectToDashboard()"></cf-button>
                </div>
            </div>

            <div class="home-cards">
                <div class="home-card" *ngFor="let ecosystem of ecosysytems"
                    (click)="redirectToInternalUrl(ecosystem.redirect)">
                    <div class="card-title">
                        <div class="brand-logo-and-name">
                            <div class="brand-img">
                                <img [src]="ecosystem.icon" [alt]="ecosystem.title" class="img-fluid"
                                    *ngIf="ecosystem.icon !== ''" />
                            </div>
                            <span class="brand-name">{{
                                ecosystem.title
                                }}</span>
                        </div>
                        <span class="click-to-action">Get started <i class="fa-solid fa-arrow-right"></i></span>
                    </div>

                    <div class="card-information">
                        <span class="home-card-sub-title">{{ ecosystem.description }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-5">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-8 col-xl-6 left-side both-side">
                    <div class="d-grid">
                        <span class="title">Our backers, believers,</span>
                        <span class="title">angels and auditor</span>

                        <br />

                        <span class="sub-title">Supported by a diverse group of investors and
                            backers, we're expanding with significant
                            commitments from institutional LPs, fueling our
                            continued growth.</span>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-xl-6 right-side both-side">
                    <div class="home-cards">
                        <div class="home-card" (click)="
                                redirectToUrl('https://outlierventures.io/')
                            ">
                            <div class="d-flex">
                                <div class="card-img">
                                    <img src="https://assets.credible.finance/homepage11.png" class="img-fluid" />
                                </div>
                                <div class="card-information">
                                    <span class="home-card-title">Outlier Ventures</span>
                                    <span class="home-card-sub-title">Accelerator</span>
                                </div>
                            </div>
                        </div>

                        <div class="home-card" (click)="redirectToUrl('https://superteam.fun/')">
                            <div class="d-flex">
                                <div class="card-img">
                                    <img src="https://assets.credible.finance/Superteam.png" alt="Superteam"
                                        class="img-fluid" />
                                </div>
                                <div class="card-information">
                                    <span class="home-card-title">Superteam</span>
                                    <span class="home-card-sub-title">Community partner</span>
                                </div>
                            </div>
                        </div>

                        <div class="home-card" (click)="redirectToUrl('https://www.sec3.dev/')">
                            <div class="d-flex">
                                <div class="card-img">
                                    <img src="https://assets.credible.finance/Sec3Favicon.png" class="img-fluid" />
                                </div>
                                <div class="card-information">
                                    <span class="home-card-title">Sec3</span>
                                    <span class="home-card-sub-title">Security Auditor</span>
                                </div>
                            </div>
                        </div>

                        <div class="home-card" (click)="redirectToUrl('https://bitswiss.capital/')">
                            <div class="d-flex">
                                <div class="card-img">
                                    <img src="https://assets.credible.finance/BitSwiss.png" alt="Superteam"
                                        class="img-fluid" />
                                </div>
                                <div class="card-information">
                                    <span class="home-card-title">BitSwiss</span>
                                    <span class="home-card-sub-title">Investor</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-6">
            <div class="background">
                <img src="https://assets.credible.finance/Image133.gif" />
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title">
                        Empowering Financial Inclusion for all
                    </h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <span class="sub-title">Tweets from
                        <span style="color: white">Credible</span>
                        community</span>
                </div>
            </div>

            <div class="twitter-cards">
                <div class="twitter-card" *ngFor="let tweet of tweetsToShow" [ngClass]="{
                        'twitter-card-active':
                            tweet.index === selectedTweetId ? true : null
                    }" [ngStyle]="{
                        'z-index': tweet.index === selectedTweetId ? 100 : 0
                    }" (click)="redirectToUrl(tweet.tweet_url)">
                    <div class="d-flex">
                        <img [src]="tweet.profile_photo" alt="homepage3" class="twitter-author-img" width="60" />

                        <div class="d-grid">
                            <span class="twitter-author-name">{{
                                tweet.name
                                }}</span>
                            <span class="twitter-author-username">{{
                                tweet.username
                                }}</span>
                        </div>
                    </div>

                    <span class="twitter-tweet-content">{{
                        tweet.content
                        }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <div class="moving-cards">
                        <div class="moving-card" *ngFor="
                                let tweet of tweetsToShow;
                                let index = index
                            " [ngClass]="{
                                'moving-card-active':
                                    tweet.index === selectedTweetId
                                        ? true
                                        : null,
                                'moving-card-inactive':
                                    tweet.index !== selectedTweetId
                                        ? true
                                        : null
                            }">
                            <div class="filled" [ngStyle]="{ width: animationWidth + '%' }"
                                *ngIf="tweet.index === selectedTweetId"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-7">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title">Content feeds for you</h1>
                </div>
            </div>

            <div class="blog-cards">
                <div class="blog-card">
                    <div class="d-flex">
                        <img src="https://assets.credible.finance/homepage1.png" alt="homepage3" class="blog-img" />
                        <span class="click-to-action">Get started <i class="fa-solid fa-arrow-right"></i></span>
                    </div>

                    <div class="blog-information">
                        <span class="blog-title">Is Loan Against Tokenised RWA possible ? Credible
                            says, why not ?</span>
                        <span class="blog-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-8">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title">Frequently asked questions</h1>
                    <span class="sub-title">Below are some common questions, please join our
                        <a href="https://twitter.com/crediblefin" target="_blank">Twitter</a>
                        for more information</span>
                </div>
            </div>

            <div class="faq-cards">
                <div class="faq-card" *ngFor="let faq of faqs" (click)="openFaq(faq.index)">
                    <div class="faq-question">
                        <span>{{ faq.question }}</span>
                        <i class="fa-solid fa-chevron-up" *ngIf="faq.index === openedFaqIndex"></i>
                        <i class="fa-solid fa-chevron-down" *ngIf="faq.index !== openedFaqIndex"></i>
                    </div>
                    <span class="faq-answer" *ngIf="faq.index === openedFaqIndex">{{ faq.answer }}</span>
                </div>
            </div>
        </div>

        <div class="section-9">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="logo" [routerLink]="'/'">
                        <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                            title="Credible Finance" loading="lazy" (click)="scrollUp()" />
                    </div>

                    <div class="community">
                        <a href="https://twitter.com/crediblefin" target="_blank">
                            <i class="fa-brands fa-twitter"></i>
                        </a>
                        <a href="https://t.me/crediblefinance" target="_blank">
                            <i class="fa-brands fa-telegram"></i>
                        </a>
                        <a href="https://medium.com/@credible_finance" target="_blank">
                            <i class="fa-brands fa-medium"></i>
                        </a>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="d-grid">
                                <span class="links-heading">Product</span>
                                <span class="links-label" routerLink="/dashboard">Liquidity</span>
                                <span class="links-label" routerLink="/liquidity">Tokenize</span>
                                <span class="links-label" routerLink="/tokenize">Airdrop</span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="d-grid">
                                <span class="links-heading">Community</span>
                                <a href="https://twitter.com/crediblefin" target="_blank"
                                    class="links-label">Twitter</a>
                                <a href="https://t.me/crediblefinance" target="_blank" class="links-label">Telegram</a>
                                <a href="https://medium.com/@credible_finance" target="_blank"
                                    class="links-label">Medium</a>
                                <span class="links-label">Career</span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="d-grid">
                                <span class="links-heading">Resources</span>
                                <a href="https://credible.gitbook.io/" target="_blank"
                                    class="links-label">Whitepaper</a>
                                <a href="https://docsend.com/view/c4nm6fpy6kfk2vez" target="_blank"
                                    class="links-label">Litepaper</a>
                                <span class="links-label">Developer Docs</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-grid">
                                <span class="links-heading">Legal</span>
                                <a (click)="redirectToUrl('/privacy-policy')" target="_blank"
                                    class="links-label">Privacy Policy</a>
                                <a (click)="redirectToUrl('/terms-condition')" target="_blank" class="links-label">Terms
                                    And Conditions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>