import {
    Component, HostListener, Inject, OnDestroy, OnInit,
    PLATFORM_ID
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { isPlatformBrowser } from '@angular/common';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

interface Tweet {
    username: string,
    profile_photo: string,
    name: string,
    tweet_url: string,
    content: string,
    index: string
}

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrl: './homepage.component.scss'
})
export class HomepageComponent implements OnInit, OnDestroy {
    platformId: object = {};

    faqs: Array<{
        question: string,
        answer: string,
        index: string
    }> = [];
    tweets: Array<Tweet> = [];
    openedFaqIndex: string = '-1';

    animationDuration: number = 100;
    animationStep: number = 1;
    animationWidth: number = 100;
    animationInterval: any;
    numberOfTweetsToShow: number = 3;
    selectedTweetIndex: number = 0;
    selectedTweetId: string = '';
    tweetsToShow: Array<Tweet> = [];
    width: any;
    window: Window;
    stats: any = {
        txn_count: 0,
        collateral_usd: 0,
        tvl_usd: 0
    };

    features: Array<{
        title: string,
        description: string,
        icon: string,
        redirect: string,
        index: number
    }> = [{
            title: 'Triple Yield',
            description: 'RWA Pools and Tokenized RWA Staking provide fixed yields in stablecoins and CRED tokens, while Synthetic RWA LPs on DEX\'s AMMs offer variable yields.',
            icon: 'https://assets.credible.finance/page1.png',
            redirect: '/liquidity',
            index: 0
        }, {
            title: 'Flexibility',
            description: 'Synthetic tokens derived from tokenized assets allow liquidity providers to swap and exit their positions without needing to interact with the RWA asset owner.',
            icon: 'https://assets.credible.finance/page2.png',
            redirect: '/tokenize',
            index: 1
        }, {
            title: 'Simplicity',
            description: 'Liquidity providers can effortlessly access curated RWA opportunities in a permissionless manner by adding liquidity to vaults, which automatically allocate to RWA pools, staking, and AMMs.',
            icon: 'https://assets.credible.finance/page3.png',
            redirect: '/tokenize',
            index: 2
        }];

    ecosysytems: Array<{
        title: string,
        description: string,
        icon: string,
        redirect: string,
        index: number
    }> = [{
            title: 'Etherfuse',
            description: 'Etherfuse has introduced the CETES Stablebond, a tokenized Mexican treasury, denominated in the local currency. This allows Mexico to benefit from the blockchain\'s growth while reducing reliance on foreign currency, utilizing blockchain technology alongside traditional sovereign bonds.',
            icon: 'https://pbs.twimg.com/profile_images/1758246030796058624/2JrJRxQ6_400x400.jpg',
            redirect: 'https://app.etherfuse.com',
            index: 0
        }, {
            title: 'Damia Capital',
            description: 'The Transactional Capital Financing SPV focuses on short-term fintech assets. Damia Capital utilizes digital tools to monitor transaction flows and collateral efficiently. This SPV is designed for investors seeking attractive risk-return investment opportunities.',
            icon: 'https://pbs.twimg.com/profile_images/1798121995340537856/jg4ylEvV_400x400.jpg',
            redirect: '/liquidity',
            index: 1
        }];

    // , {
    //     title: '',
    //     description: '',
    //     redirect: '',
    //     icon: '',
    //     index: 2
    // }

    controls: Array<{
        title: string,
        description: string,
        index: number
    }> = [{
            title: 'Supply',
            description: 'Provide USDC, USDT, and SOL liquidity to tokenization pools, bonding curves, and vaults to earn triple yield benefits.',
            index: 0
        }, {
            title: 'Stake',
            description: 'Lock your institutional tokenized assets for liquid staking to receive tradable LST and CRED yields.',
            index: 1
        }, {
            title: 'Tokenize',
            description: 'Launch your tokenized asset by tokenizing private credit funds, private equity, bonds, commercial properties, artist IPs, or even your pet dragon.',
            index: 2
        }, {
            title: 'Govern',
            description: 'Join the RWA club by staking CRED tokens and get rewarded for participating in project proposals and roadmap decision-making.',
            index: 3
        }];

    selectedControlIndex: number = 0;

    numberFlippingAnimation: any = {
        steps: 15,
        interval: 100,
        clearIntervals: {}
    };

    constructor(private httpService: HttpService, private cfAlertService: CfAlertService, @Inject(PLATFORM_ID) platformId: object,
        private router: Router) {
        this.platformId = platformId;
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;
    }

    ngOnInit(): void {
        this.width = this.window.outerWidth;

        this.getHomepageStats();

        if (this.width < 768)
            this.numberOfTweetsToShow = 1;

        this.getFaqs();
        this.getTweets();
    }

    ngOnDestroy(): void {
        clearInterval(this.animationInterval);

        for (const key in this.numberFlippingAnimation.clearIntervals) {
            if (this.numberFlippingAnimation.clearIntervals.hasOwnProperty(key))
                clearInterval(this.numberFlippingAnimation.clearIntervals[key]);
        }
    }

    startTweetAnimation() {
        this.animationInterval = setInterval(() => {
            this.animationWidth = this.animationWidth - this.animationStep;

            if (this.animationWidth <= 0) {
                this.tweets = this.arrayRotate(this.tweets);
                this.selectedTweetId = this.tweets[this.selectedTweetIndex].index;

                this.animationWidth = 100; // percentage

                const startIndex = this.selectedTweetIndex - Math.floor(this.numberOfTweetsToShow / 2);
                const endIndex = this.selectedTweetIndex + Math.floor(this.numberOfTweetsToShow / 2);

                const tweetsToShow = this.tweets.slice(startIndex, endIndex + 1);

                for (let i = 0; this.tweetsToShow.length < this.numberOfTweetsToShow; i++)
                    tweetsToShow.push(this.tweets[i]);

                this.tweetsToShow = tweetsToShow;
                this.selectedControlIndex = (this.selectedControlIndex + 1) % this.controls.length;
            }
        }, this.animationDuration);
    }

    arrayRotate(arr: Array<any>) {
        arr.unshift(arr.pop());

        return arr;
    }

    getTweets() {
        this.tweets = [{
            username: '@SuperteamIN',
            name: 'Superteam India',
            profile_photo: 'https://pbs.twimg.com/profile_images/1623575742411051008/oFqnid5X_400x400.jpg',
            tweet_url: 'https://x.com/SuperteamIN/status/1803820984371147254?s=19',
            content: 'Congratulations to team @crediblefin as they double down on furthering RWA adoption & financing.',
            index: 'tweet-0'
        }, {
            username: '@SolanaSummitOrg',
            name: 'Solana Summit | APAC June 20-22',
            profile_photo: 'https://pbs.twimg.com/profile_images/1773805047542870016/alteHk4i_400x400.jpg',
            tweet_url: 'https://x.com/SolanaSummitOrg/status/1805220453121941573?s=19',
            content: 'Just bought a new Tesla with Credible Points. Thanks for the rewards!',
            index: 'tweet-1'
        }, {
            username: '@SolanaSummitOrg',
            name: 'Solana Summit | APAC June 20-22',
            profile_photo: 'https://pbs.twimg.com/profile_images/1773805047542870016/alteHk4i_400x400.jpg',
            tweet_url: 'https://x.com/SolanaSummitOrg/status/1804468489635373142?s=19',
            content: '4th prize goes to @crediblefin The liquidity layer for tokenization, unlocking credit for tokenized assets, private credit and trade finance.',
            index: 'tweet-2'
        }, {
            username: '@SuperteamAE',
            name: 'Superteam UAE',
            profile_photo: 'https://pbs.twimg.com/profile_images/1762742051941056512/K5vz6H7L_400x400.jpg',
            tweet_url: 'https://x.com/SuperteamIN/status/1803820984371147254?s=19',
            content: 'We had @Shrikant_DB from @crediblefin join us and talk more about tokenizing RWAs and building out of the UAE',
            index: 'tweet-3'
        }, {
            username: '@SuperteamMY',
            name: 'Superteam Malaysia',
            profile_photo: 'https://pbs.twimg.com/profile_images/1696056330002587648/NxUEzaZ-_400x400.jpg',
            tweet_url: 'https://x.com/SuperteamMY/status/1808378392066343142?s=19',
            content: 'Earn your crypto by being a part of @crediblefin today ! Don\'t snooze',
            index: 'tweet-4'
        }, {
            username: '@SuperteamAE',
            name: 'Superteam UAE',
            profile_photo: 'https://pbs.twimg.com/profile_images/1762742051941056512/K5vz6H7L_400x400.jpg',
            tweet_url: 'https://x.com/SuperteamAE/status/1808374298773459152',
            content: 'Earn your first crypto, don\'t buy it',
            index: 'tweet-5'
        }];

        this.selectedTweetIndex = Math.floor(this.numberOfTweetsToShow / 2);

        const startIndex = this.selectedTweetIndex - Math.floor(this.numberOfTweetsToShow / 2);
        const endIndex = this.selectedTweetIndex + Math.floor(this.numberOfTweetsToShow / 2);

        this.tweetsToShow = this.tweets.slice(startIndex, endIndex + 1);
        this.selectedTweetId = this.tweets[this.selectedTweetIndex].index;

        if (isPlatformBrowser(this.platformId))
            this.startTweetAnimation();
    }

    getFaqs() {
        this.faqs = [{
            index: '0',
            question: 'What is asset tokenization?',
            answer: 'Asset tokenization is the process of converting physical or digital assets into blockchain tokens that represent the value and ownership of those assets.'
        }, {
            index: '1',
            question: 'What is proof of liquidity?',
            answer: 'Proof of liquidity is a mechanism that ensures all tokenized assets on our platform are fully backed and transparent, providing enhanced security for users.'
        }, {
            index: '2',
            question: 'How does Credible support both permissioned and permissionless tokenization?',
            answer: 'Credible offers flexibility by allowing assets to be tokenized either with permissions (regulated and controlled) or without permissions (open and decentralized), catering to various user needs and regulatory requirements.'
        }, {
            index: '3',
            question: 'What types of assets can be tokenized on Credible?',
            answer: 'Various assets, including real estate, art, private equity, and more, can be tokenized on the Credible platform.'
        }, {
            index: '4',
            question: 'What is the liquidity layer, and how does it work?',
            answer: 'The liquidity layer in Credible facilitates private credit, lending, borrowing of tokenized assets, and RWASwap functions, ensuring there is always enough liquidity to support transactions.'
        }, {
            index: '5',
            question: 'How can I earn as a liquidity provider on Credible?',
            answer: 'You can earn by lending your tokenized assets on the platform or participating in RWASwap functions, which offer competitive returns and rewards.'
        }, {
            index: '6',
            question: 'What is RWASwap?',
            answer: 'RWASwap is a function on the Credible platform that allows users to swap real-world assets (RWAs) in a decentralized manner, providing liquidity and flexibility in asset management.'
        }, {
            index: '7',
            question: 'Who are the backers and believers of Credible?',
            answer: 'Credible is supported by a diverse group of investors, including both retail and institutional users, with significant commitments from institutional LPs.'
        }, {
            index: '8',
            question: 'What are Credible Points?',
            answer: 'Credible Points are reward points that users earn for various activities on the platform, such as providing liquidity, referring new users, or participating in governance.'
        }, {
            index: '9',
            question: 'How can I earn Credible Points?',
            answer: 'You can earn Credible Points by engaging in activities like participating in social media community groups,  providing liquidity, participating in RWASwap, referring friends, and contributing to the community.'
        }, {
            index: '10',
            question: 'How can I contact Credible for more information?',
            answer: 'You can reach out to our support team via the contact form on our website or email us at [support@credible.com].'
        }];
    }

    openFaq(index: string) {
        if (this.openedFaqIndex === index)
            this.openedFaqIndex = '-1';

        else
            this.openedFaqIndex = index;
    }

    redirectToUrl(url: string) {
        this.window.open(url, '_blank');
    }

    redirectToInternalUrl(url: string) {
        this.window.open(url, '_blank');
    }

    redirectToDashboard() {
        if (this.httpService.user.wallet_address === '')
            this.router.navigate([ '/liquidity' ]);

        else
            this.router.navigate([ '/dashboard' ]);
    }

    getHomepageStats() {
        this.httpService.getHomepageStats().subscribe(res => {
            const data = res.data;

            if (isPlatformBrowser(this.platformId))
                this.startNumberFlippingAnimation('tvl_usd', 0, data.tvl_usd);

            else
                this.stats = data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    startNumberFlippingAnimation(key: string, start: number, end: number) {
        const step = (end - start) / this.numberFlippingAnimation.steps;
        let current = start;
        let i = 0;

        this.numberFlippingAnimation.clearIntervals[key] = setInterval(() => {
            current += step;

            if (i === this.numberFlippingAnimation.steps - 1)
                current = end;

            this.stats[key] = current;

            i++;

            if (i === this.numberFlippingAnimation.steps)
                clearInterval(this.numberFlippingAnimation.clearIntervals[key]);
        }, this.numberFlippingAnimation.interval);
    }

    selectedControl(index: number) {
        this.selectedControlIndex = index;
    }
    scrollUp() {
        console.log('Scroll');

        this.window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
}
