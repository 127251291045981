import {
    Component, Inject, OnDestroy, OnInit,
    PLATFORM_ID
} from '@angular/core';
import { getWindow } from 'ssr-window';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { MatStepper } from '@angular/material/stepper';
import { TwitterService } from '../../services/twitter.service';
import { TelegramService } from '../../services/telegram.service';
import { DiscordService } from '../../services/discord.service';
import { SuperTeamService } from '../../services/super_team.service';
import getWalletBalanceMainAddress from '../helpers/getWalletBalanceMainAddress';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { isPlatformBrowser } from '@angular/common';

declare let Telegram: any;

interface TelegramData {
    auth_date: number;
    first_name: string;
    hash: string;
    id: number;
    last_name: string;
    username: string;
    // I think there could be other properties too
}

@Component({
    selector: 'app-loyalty',
    templateUrl: './loyalty.component.html',
    styleUrl: './loyalty.component.scss'
})
export class LoyaltyComponent implements OnInit, OnDestroy {
    platformId: object = {};

    window: Window;
    steps: any = {
        twitter: false,
        telegram: false,
        discord: false,
        super_team: false,
        sol_balance: false
    };
    leaderboard: Array<any> = [];
    leaderboard_count: number = 0;
    leaderboard_page: number = 0;
    leaderboard_limit: number = 10;

    rewards: any = {
        twitter: 500,
        telegram: 500,
        discord: 500,
        super_team: 500,
        sol_balance: 500
    };

    animationProgress: any = {
        twitter: 0,
        telegram: 0,
        discord: 0
    };

    animationProgressInterval: any = {
        connectwallet: 300,
        kyc: 50,
        twitter: 300,
        telegram: 300,
        discord: 300
    };

    public telegram: any = {
        accountConnected: false,
        joinGroupLoading: false,
        checkGroupButton: false,
        loginButton: true,
        checkGroupJoinedIntervalId: null,
        groupJoined: false,
        groupCheckAttempt: 0
    };

    public twitter: any = {
        loading: false,
        accountConnected: false
    };

    public discord: any = {
        loading: false,
        loginButton: true,
        serverJoined: false,
        checkGroupButton: false,
        checkGroupJoinedIntervalId: null,
        accountConnected: false,
        groupCheckAttempt: 0
    };

    public super_team: any = {
        loading: false,
        joined: false
    };

    public sol_balance: any = {
        loading: false,
        reward_claimed: false
    };

    total_points: number = 0;
    cred_points: number = 0;
    sol_balance_points: number = 0;
    super_team_points: number = 0;
    telegram_points: number = 0;
    discord_points: number = 0;
    twitter_points: number = 0;

    referral_points: number = 0;

    total: number = 0;

    nfts: Array<any> = [];
    theme: any;

    referral_link: any;

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService,
        private twitterService: TwitterService, private telegramService: TelegramService,
        private discordService: DiscordService, private superTeamService: SuperTeamService, 
        private route: ActivatedRoute, private router: Router,
        private clipboardService: ClipboardService, @Inject(PLATFORM_ID) platformId: object) {
        console.log('LoyaltyComponent constructor()');

        this.window = getWindow();
        this.platformId = platformId;
    }

    ngOnInit() {
        console.log('this.httpService.user', this.httpService.user);

        console.log('ActivatedRoute : ', this.route.snapshot.queryParams);

        this.referral_link = this.httpService?.user?.referral_link;
        console.log('referral_link', this.referral_link);

        if (this.httpService.user.telegram && this.httpService.user.telegram.id > 0) {
            this.telegram.accountConnected = true;
            this.telegram.loginButton = false;

            if (this.httpService.user.telegram?.group_joined) {
                this.telegram.groupJoined = true;

                this.steps.telegram = true;
            }
        }

        if ((this.httpService.user.twitter && this.httpService.user.twitter.user_id > 0) || (this.route.snapshot.queryParams['twitter_connected'])) {
            this.twitter.accountConnected = true;
            this.steps.twitter = true;
        }

        if ((this.httpService.user.discord && this.httpService.user.discord.id !== '') || (this.route.snapshot.queryParams['discord_connected'])) {
            this.discord.accountConnected = true;
            this.discord.loginButton = false;
        }

        if (this.httpService.user.discord && this.httpService.user.discord.id !== '' && this.httpService.user.discord.server_joined) {
            this.discord.serverJoined = true;
            this.steps.discord = true;
        }

        else if (isPlatformBrowser(this.platformId)) 
            this.discord.checkGroupJoinedIntervalId = setInterval(() => this.checkDiscordGroupJoined(false), 2000);

        if (this.httpService.user.super_team_member) {
            this.super_team.member = true;
            this.steps.super_team = true;
        }

        if (this.httpService.user.sol_balance_reward_claimed) {
            this.sol_balance.reward_claimed = true;
            this.steps.sol_balance = true;
        }

        this.animateStepRewards();
        this.calculateTotalAirdrop();

        this.getNfts();
        this.getCurrentTheme();
        this.getLoyaltyPoints();
        this.getLoyaltyLeaderboard();
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    loginWithTelegram() {
        if (this.httpService.user.wallet_address === '') 
            return this.connectWallet();

        if (!('Telegram' in this.window))
            return;

        console.log(this.window.Telegram);

        const TELEGRAM_BOT_ID = '6562280465';

        const option = {
            bot_id: TELEGRAM_BOT_ID,
            request_access: true,
            lang: 'en'
        };

        Telegram.Login.auth(option, (data: TelegramData) => {
            if (!data)
                console.log('authorization failed');

            console.log(data);

            this.saveTelegramUser(data);
        });
    }

    pageChangedLeaderboard(event: any) {
        this.leaderboard_page = event;
        this.getLoyaltyLeaderboard();
    }

    saveTelegramUser(telegramLoginData: any) {
        this.telegramService.saveTelegramUser(telegramLoginData).subscribe((res) => {
            this.cfAlertService.showSuccess(res.message);

            this.telegram.accountConnected = true;
            this.telegram.loginButton = false;
            this.telegram.checkGroupButton = true;

            if (isPlatformBrowser(this.platformId)) 
                this.telegram.checkGroupJoinedIntervalId = setInterval(() => this.checkTelegramGroupJoined(false), 2000);
        }, (err: HttpErrorResponse) => {
            console.error('saveTelegramUser error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.telegram.joinGroupLoading = false;
        });
    }

    checkTelegramGroupJoined(showError: boolean) {
        this.telegramService.checkTelegramGroupJoined().subscribe((res) => {
            clearInterval(this.telegram.checkGroupJoinedIntervalId);

            this.cfAlertService.showSuccess(res.message);

            this.telegram.groupJoined = true;
            this.telegram.loginButton = false;
            this.telegram.checkGroupButton = false;

            this.getLoyaltyPoints();
        }, (err: HttpErrorResponse) => {
            console.error('checkTelegramGroupJoined error');
            console.error(err);

            if (showError)
                this.cfAlertService.showError(err);

            this.telegram.joinGroupLoading = false;
            this.telegram.groupCheckAttempt++;

            if (this.telegram.groupCheckAttempt > 10)
                clearInterval(this.telegram.checkGroupJoinedIntervalId);
        });
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getLoyaltyPoints() {
        this.httpService.getLoyaltyPoints().subscribe((res) => {
            this.total_points = res.data.total_points;

            this.cred_points = res.data.cred_points ? res.data.cred_points : 0;

            this.telegram_points = res.data.telegram_points ? res.data.telegram_points : res.default_points.telegram;
            this.discord_points = res.data.discord_points ? res.data.discord_points : res.default_points.discord;
            this.twitter_points = res.data.twitter_points ? res.data.twitter_points : res.default_points.twitter;

            this.super_team_points = res.data.super_team_points ? res.data.super_team_points : res.default_points.super_team;
            this.sol_balance_points = res.data.sol_balance_points ? res.data.sol_balance_points : 0;

            this.referral_points = res.data.referral_points ? res.data.referral_points : 0;
        }, (err: HttpErrorResponse) => {
            console.error('checkTelegramGroupJoined error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getLoyaltyLeaderboard() {
        this.httpService.getLoyaltyLeaderboard(this.leaderboard_limit, this.leaderboard_page).subscribe((res) => {
            this.leaderboard = res.data.users;
            this.leaderboard_count = res.data.count;
        }, (err: HttpErrorResponse) => {
            console.error('checkTelegramGroupJoined error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    onStepChange(temp: any) {
        console.log('onStepChange', temp);
    }

    animateStepRewards() {
        for (const key in this.rewards)
            this.animateStepReward(key, this.animationProgressInterval[key]);
    }

    animateStepReward(step: string, interval: number) {
        if (isPlatformBrowser(this.platformId)) {
            const intervalId = setInterval(() => {
                if (this.rewards[step] > this.animationProgress[step])
                    this.animationProgress[step]++;

                else
                    clearInterval(intervalId);
            }, interval);
        }
    }

    discordIdChanged(value: string) {

    }

    openTwitter() {
        const OPEN_TWITTER_URL = 'https://twitter.com/crediblefin';

        this.window.open(OPEN_TWITTER_URL, '_blank');
    }

    openDiscord() {
        const OPEN_DISCORD_URL = 'https://discord.gg/sN72BYTq';

        this.window.open(OPEN_DISCORD_URL, '_blank');
    }

    calculateTotalAirdrop() {
        let total = 0;

        for (const key in this.steps) {
            if (this.steps[key])
                total += this.rewards[key];
        }

        this.total = total;
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        stepper.next();
    }

    ngOnDestroy(): void {
        console.log('LoyaltyComponent ngOnDestroy()');

        clearInterval(this.telegram.checkGroupJoinedIntervalId);
        clearInterval(this.discord.checkGroupJoinedIntervalId);
    }

    getRequestToken() {
        if (this.httpService.user.wallet_address === '') 
            return this.connectWallet();

        this.twitter.loading = true;

        this.twitterService.getRequestToken().subscribe((res) => {
            console.log(res);

            const TWITTER_AUTH_BASE_URL = 'https://api.twitter.com/oauth/authenticate?oauth_token';

            this.window.location.href = `${TWITTER_AUTH_BASE_URL}=${res.data.oauth_token}`;
        }, (err: HttpErrorResponse) => {
            console.error('getRequestToken error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.twitter.loading = false;
        });
    }

    // not supported with free twitter API plan
    checkFollowingCredible() {
        this.twitter.loading = true;

        this.twitterService.checkFollowingCredible().subscribe((res) => {
            console.log(res);
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.twitter.loading = false;
        });
    }

    getDiscordAuthorizeUrl() {
        if (this.httpService.user.wallet_address === '') 
            return this.connectWallet();

        this.discord.loading = true;

        this.discordService.getAuthorizeUrl().subscribe((res) => {
            console.log(res);

            this.window.location.href = res.data.url;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.discord.loading = false;
        });
    }

    checkDiscordGroupJoined(showError: boolean) {
        this.discordService.checkDiscordGroupJoined().subscribe((res) => {
            clearInterval(this.discord.checkGroupJoinedIntervalId);

            this.cfAlertService.showSuccess(res.message);

            this.discord.serverJoined = true;
            this.discord.loginButton = false;
            this.discord.checkGroupButton = false;
            this.steps.discord = true;

            this.getLoyaltyPoints();
        }, (err: HttpErrorResponse) => {
            console.error('checkDiscordGroupJoined error');
            console.error(err);

            if (showError)
                this.cfAlertService.showError(err);

            this.discord.joinGroupLoading = false;
            this.discord.groupCheckAttempt++;

            console.log('this.discord.groupCheckAttempt', this.discord.groupCheckAttempt);

            if (this.discord.groupCheckAttempt > 10)
                clearInterval(this.discord.checkGroupJoinedIntervalId);
        });
    }

    getNfts() {
        this.httpService.getNfts().subscribe(res => {
            this.nfts = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    checkSuperTeamUser() {
        this.super_team.loading = true;

        this.superTeamService.checkSuperTeamUser().subscribe((res) => {
            console.log(res);

            this.cfAlertService.showSuccess(res.message);

            this.super_team.member = true;
            this.steps.super_team = true;

            this.super_team.loading = false;

            this.getLoyaltyPoints();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.super_team.loading = false;
        });
    }

    async checkSolanaTokenBalance() {
        this.sol_balance.loading = true;

        const balance = await getWalletBalanceMainAddress(this.httpService.network, this.httpService.user.wallet_address, 'sol');

        this.superTeamService.checkSolanaTokenBalance({
            balance
        }).subscribe((res) => {
            console.log(res);

            this.cfAlertService.showSuccess(res.message);

            this.sol_balance.reward_claimed = true;
            this.steps.sol_balance = true;

            this.sol_balance.loading = false;

            this.getLoyaltyPoints();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.sol_balance.loading = false;
        });
    }

    redirectPoints() {
        this.window.open('https://medium.com/@credible_finance/earn-daily-airdrops-with-credible-points-03f91b2364c6', '_blank');
    }

    copyReferLink() {
        this.clipboardService.copy(this.referral_link);
        this.cfAlertService.showSuccess('Link copied');
    }
}
