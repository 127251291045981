<div class="app-lend-pools container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="tabs">
                <span class="tab" (click)="changeTab(tab.key)" [ngClass]="{ 'tab-active': currentTab === tab.key }"
                    *ngFor="let tab of tabs">{{ tab.name }}</span>
            </div>
            <div class="tabs-divider"></div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="pools-container">
                <div *ngFor="let pool of pools">
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'private-credit'"></app-lend-default>
                    <app-lend-private-equity [pool]="pool"
                        *ngIf="pool.category_id === 'private-equity'"></app-lend-private-equity>
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'partner-protocols'"></app-lend-default>
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'vault'"></app-lend-default>
                </div>
                <div class="row" *ngIf="loading">
                    <div class="loader-content">
                        <cf-loader class="loader"></cf-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>